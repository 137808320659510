import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExamRoom } from '@services/api'
import { dataGroupingTest, _patientTest } from '@utils/constants'
import { filter } from 'lodash'

type appointementState = {
  data: Array<any>
  listPatient: Array<any>
  currentFilter?: string
  resources: Array<any>
  roomsSelected: Array<any>
  typeRoomSelected?: number
  hours: {
    startHour: number
    endHour: number
    meridiem: string
  }
  cellDuration: number
  appointmentDialog: {
    openAdd?: boolean
    openUpdate?: boolean
    data: Object
  }

  currentAppointmentPatient: any
  defaultCurrentDate: Date
  isValidate: boolean
  isNewAppointmentFinished: boolean
}

const initialState: appointementState = {
  data: dataGroupingTest,
  listPatient: _patientTest,

  currentFilter: '',
  resources: [],
  roomsSelected: [],
  typeRoomSelected: undefined,
  hours: {
    startHour: 8,
    endHour: 18,
    meridiem: 'ampm',
  },
  cellDuration: 10,
  appointmentDialog: {
    openAdd: false,
    openUpdate: false,
    data: {},
  },
  currentAppointmentPatient: null,
  defaultCurrentDate: new Date(),
  isValidate: false,
  isNewAppointmentFinished: false,
}

const appointementSlice = createSlice({
  name: 'appointement',
  initialState,
  reducers: {
    getAppointement: (state, action: PayloadAction<any>) => {
      state.data = action.payload
    },

    addAppointement: (state, action: PayloadAction<any>) => {
      state.data = [...state.data, action.payload]
    },

    openAddAppointmentDialog: (
      state,
      action: PayloadAction<appointementState['appointmentDialog']>,
    ) => {
      state.appointmentDialog = {
        openAdd: true,
        data: action.payload.data,
      }
    },

    openUpdateAppointmentDialog: (
      state,
      action: PayloadAction<appointementState['appointmentDialog']>,
    ) => {
      state.appointmentDialog = {
        openUpdate: true,
        data: action.payload.data,
      }
    },

    closeAppointmentDialog: (state) => {
      state.appointmentDialog = {
        openAdd: false,
        openUpdate: false,
        data: {},
      }
    },

    setCellDuration: (state, action: PayloadAction<number>) => {
      state.cellDuration = action.payload
    },
    setCurrentFilter: (state, action: PayloadAction<any>) => {
      state.currentFilter = action.payload
    },

    setCurrentAppointmentPatient: (state, action: PayloadAction<any>) => {
      state.currentAppointmentPatient = action.payload
    },

    setResources: (state, action: PayloadAction<Array<ExamRoom>>) => {
      const newInstances = [
        {
          fieldName: 'examRoomId',
          text: 'rums',
          title: 'rums',
          instances: action.payload.map((_room) => ({
            text: `${_room.label}|${_room.color}|${_room.site.label}`,
            id: _room.id,
          })),
        },
      ]

      state.resources = newInstances
    },

    setHours: (state, action: PayloadAction<appointementState['hours']>) => {
      state.hours = action.payload
    },

    setRoomsSelected: (
      state,
      action: PayloadAction<appointementState['roomsSelected']>,
    ) => {
      state.roomsSelected = action.payload
    },

    setTypeRoomSelected: (state, action: PayloadAction<number | undefined>) => {
      state.typeRoomSelected = action.payload
    },

    onSelectRoomChange: (state, action: PayloadAction<string>) => {
      let newSelectedRoom: Array<any> = []
      if (state.roomsSelected.includes(action.payload)) {
        //inside

        newSelectedRoom = filter(
          state.roomsSelected,
          (o) => o.key !== action.payload,
        )
      } else {
        newSelectedRoom = [...state.roomsSelected, action.payload]
      }
      state.roomsSelected = newSelectedRoom
    },

    onSubmitAppointment: (state, action: PayloadAction<boolean>) => {
      state.isValidate = action.payload
    },

    setNewAppointmentFinished: (state, action: PayloadAction<boolean>) => {
      state.isNewAppointmentFinished = action.payload
    },

    setDefaultCurrentDate: (state, action: PayloadAction<Date>) => {
      state.defaultCurrentDate = action.payload
    },

    addPatient: (state, action: PayloadAction<any>) => {
      state.listPatient = [...state.listPatient, action.payload]
    },
  },
})

export const {
  getAppointement,
  setCellDuration,
  setCurrentFilter,
  setHours,
  onSelectRoomChange,
  setRoomsSelected,
  setResources,
  openAddAppointmentDialog,
  openUpdateAppointmentDialog,
  closeAppointmentDialog,
  setTypeRoomSelected,
  setCurrentAppointmentPatient,
  addAppointement,
  setDefaultCurrentDate,
  onSubmitAppointment,
  setNewAppointmentFinished,
} = appointementSlice.actions

export default appointementSlice.reducer
