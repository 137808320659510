import { createTheme } from '@material-ui/core'

// const defaultTheme = createMuiTheme()
export default createTheme({
  palette: {
    primary: { main: '#009DA0' },
    secondary: { main: '#E91E63' },
    text: {
      // primary: '#464855',
      // secondary: '#FFF',
      primary: '#000',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: [
      'Rubik',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        display: 'flex',
      },
    },

    //   MuiInputLabel: {
    //     root: {
    //       textTransform: 'none',
    //       // backgroundColor: 'white',
    //     },
    //   },
    //   MuiStepLabel: {
    //     label: {
    //       color: 'inherit',
    //     },
    //   },
    //   MuiTextField: {
    //     root: {
    //       // backgroundColor: 'white'
    //     },
    //   },
    // MuiButton: {
    //   root: {
    //     '&:focus': {
    //       outline: 'none',
    //     },
    //   },
    // },
    MuiIcon: {
      root: {
        overflow: 'visible',
      },
    },
    //   MuiDrawer: {
    //     paper: {
    //       // backgroundColor: colors.grey[900],
    //       // color: colors.grey[100],
    //       '& .MuiListItemIcon-root': {
    //         color: 'inherit',
    //         // paddingLeft: 7,
    //       },
    //       '& :hover': {
    //         color: 'white',
    //       },
    //       '& .MuiDivider-root': {
    //         backgroundColor: 'inherit',
    //       },
    //     },
    //   },
  },
})
