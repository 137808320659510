import { all } from 'redux-saga/effects'

import userSagas from './userSaga'
import patientSagas from './patientSaga'
import doctorSagas from './doctorSaga'
import studySagas from './studySaga'
import finessSagas from './finessSaga'
import rppsSagas from './rppsSaga'
import logSagas from './logSaga'
import clientSagas from './clientSaga'
import siteSagas from './siteSaga'
import examRoomSagas from './examRoomSaga'

export default function* rootSaga() {
  yield all([
    ...patientSagas,
    ...doctorSagas,
    ...studySagas,
    ...finessSagas,
    ...rppsSagas,
    ...userSagas,
    ...logSagas,
    ...clientSagas,
    ...siteSagas,
    ...examRoomSagas,
  ])
}
