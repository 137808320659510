export default class StorageService {
  static getAuthToken() {
    return localStorage.getItem('authToken')
  }
  static setAuthToken(token: string) {
    console.log('SET_AUTH_TOKEN')
    localStorage.setItem('authToken', token)
  }
  static removeAuthToken() {
    console.log('REMOVE_AUTH_TOKEN')
    localStorage.removeItem('authToken')
  }
}
