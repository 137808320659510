import { useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'

import { SnackbarProvider } from 'notistack'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { locales } from './locales'
import Notifier from './containers/Notifier'
import Router from './containers/Router'
import { RootState } from '@state/store'

export default function App() {
  const { language } = useSelector(({ auth }: RootState) => ({
    language: auth.language,
  }))
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider locale={language} messages={locales(language)}>
        <SnackbarProvider>
          <Router />
          <Notifier />
          {/* <AutoLogout /> */}
          {/* <AutoLogoutAlert /> */}
        </SnackbarProvider>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  )
}
