import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  LoginDto,
  AuthService,
  UserService,
  LocalUserRegisterPatientDto,
  LocalUserRegisterFromMailDto,
  LocalUserForgetPasswordDto,
  CreatePhoneAuthMethod,
  PhoneVerifyRequest,
  LocalUserResetPasswordDto,
  LocalUserUpdatePasswordDto,
  Account,
} from '@services/api'
import { FirstConnectionDto } from '@services/extendedType'
import StorageService from '@services/storage'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import history from '@services/history'
import { loginFulfilled } from '@state/reducers/authReducer'

export const login = createAsyncThunk(
  'auth/login',
  async (body: LoginDto, { dispatch }) => {
    try {
      const { user, token } = await AuthService.login({
        body,
      })
      StorageService.setAuthToken(token as string)
      dispatch(loginFulfilled(user))
    } catch (e: any) {
      if (e.response) {
        dispatch(
          enqueueSnackbar({ type: 'error', message: e.response.data.message }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
      throw e
    }
  },
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    await AuthService.logout()
    StorageService.removeAuthToken()
    dispatch(
      enqueueSnackbar({
        type: 'success',
        message: 'You have been disconnected',
      }),
    )
  },
)

export const registerUser = createAsyncThunk(
  'auth/register',
  async (body: LocalUserRegisterPatientDto, { dispatch, rejectWithValue }) => {
    try {
      const { token, user } = await AuthService.createUserFirstConnection({
        body,
      })
      StorageService.setAuthToken(token as string)
      const profile: Account = await UserService.findMyAccount()
      dispatch(
        enqueueSnackbar({ type: 'success', message: 'Register success' }),
      )
      return { user, profile }
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
      return rejectWithValue(e.response.data)
    }
  },
)

export const firstConnexion = createAsyncThunk(
  'auth/first-connexion',
  async (params: FirstConnectionDto, { dispatch }) => {
    try {
      await AuthService.isValidFirstConnection(params)
      dispatch(
        enqueueSnackbar({ type: 'success', message: 'Authentication success' }),
      )
      history.push(
        `/auth/register?.id=${params.accessionNumber}&b=${params.birthDate}`,
      )
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const verifyEmail = createAsyncThunk(
  'auth/verify-email',
  async (body: LocalUserRegisterFromMailDto, { dispatch }) => {
    try {
      await AuthService.createLocalUserFromEmail({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'User successfully registered',
        }),
      )
      history.push('/auth/login')
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const updateEmail = createAsyncThunk(
  'auth/update-email',
  async (body: LocalUserForgetPasswordDto, { dispatch }) => {
    try {
      await AuthService.updateEmail({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'A code has been sent to you phone',
        }),
      )
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const updatePhone = createAsyncThunk(
  'auth/update-phone',
  async (body: CreatePhoneAuthMethod, { dispatch }) => {
    try {
      await UserService.sendPhoneVerification({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'Email successfully updated',
        }),
      )
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)
export const verifyCode = createAsyncThunk(
  'auth/verify-code',
  async (body: PhoneVerifyRequest, { dispatch }) => {
    try {
      await UserService.phoneVerify({ body })
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'Phone successfully updated',
        }),
      )
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const forgottenPassword = createAsyncThunk(
  'auth/forgotten-password',
  async (params: LocalUserForgetPasswordDto, { dispatch }) => {
    try {
      await AuthService.forgottenPassword(params)
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'An email has been sent to you.',
        }),
      )
      history.push('/auth/login')
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)
export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (
    params: { id: number; body: LocalUserResetPasswordDto },
    { dispatch },
  ) => {
    try {
      AuthService.resetPassword(params)
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'Password successfully reset',
        }),
      )
      history.push('/auth/login')
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)
export const updatePassword = createAsyncThunk(
  'auth/update-password',
  async (
    params: { id: number; body: LocalUserUpdatePasswordDto },
    { dispatch },
  ) => {
    try {
      AuthService.updatePassword(params)
      dispatch(
        enqueueSnackbar({
          type: 'success',
          message: 'Password successfully updated',
        }),
      )
    } catch (e: any) {
      dispatch(
        enqueueSnackbar({ type: 'error', message: e.response.data.message }),
      )
    }
  },
)

export const getCaptcha = createAsyncThunk('auth/captcha', async () => {
  return await AuthService.getCaptcha()
})
