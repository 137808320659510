import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ClientDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type clientState = {
  clients: {
    totalCount: number
    datas: ClientDto[]
  }
  selected?: ClientDto
  columnOrder: string[]
}

const initialState: clientState = {
  clients: {
    totalCount: 0,
    datas: [],
  },
  selected: undefined,
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringClient',
    'open',
    'actions',
  ],
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<PaginatedDto<ClientDto>>) => {
      state.clients = action.payload
    },
    removeClients: (state) => {
      state.clients = initialState.clients
    },
    addClient: (state, action: PayloadAction<ClientDto>) => {
      state.clients.datas = [...state.clients.datas, action.payload]
      state.clients.totalCount += 1
    },
    setSelected: (state, action: PayloadAction<ClientDto>) => {
      state.selected = action.payload
    },
    removeSelected: (state) => {
      state.selected = initialState.selected
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    cleanClient: () => initialState,
  },
})

export const {
  setClients,
  removeClients,
  addClient,
  setSelected,
  removeSelected,
  setColumnOrder,
  cleanClient,
} = clientSlice.actions

export default clientSlice.reducer
