import { createAsyncThunk } from '@reduxjs/toolkit'
import { MemberService } from '../../services/api'
import { Actions } from '../actions/actions'

export const findAllMembers = createAsyncThunk(
  Actions.MEMBER_FIND_ALL,
  async (
    params: { search?: string; limit?: number; skip?: number },
    { rejectWithValue },
  ) => {
    try {
      const members: any = await MemberService.findAll(params)
      return members
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
