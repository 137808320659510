import { createAsyncThunk } from '@reduxjs/toolkit'
import { ExamService } from '../../services/api'
import { Actions } from '../actions/actions'

export const findExams = createAsyncThunk(
  Actions.EXAMS_FIND_ALL,
  async (
    params: { examTypes?: number[]; examRoomId?: number },
    { rejectWithValue },
  ) => {
    try {
      return await ExamService.findAll(params)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
