import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { Box, Grid, Icon, Paper, Theme, makeStyles } from '@material-ui/core'
import { truncate } from 'lodash'

import { EcSpaceVertical } from '@utils/Spacing'
import Text from '@components/Text'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    color: '#fff',
  },
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  grid: {
    '& div:nth-child(3n) a > div': {
      background: 'linear-gradient(45deg,#843cf7,#38b8f2)',
    },
    '& div:nth-child(3n+1) a > div': {
      backgroundImage: 'linear-gradient(45deg,#23bcbb,#45e994)',
    },
    '& div:nth-child(3n+2) a > div': {
      backgroundImage: 'linear-gradient(45deg,#000,#53346d)',
    },
  },
}))

export const adminLinks = [
  {
    to: '/a/schedule',
    title: 'Calendar',
    icon: 'fa fa-calendar-alt',
  },
  {
    to: '/a/patientappointment',
    title: 'Patient Appointment',
    icon: 'far fa-calendar-check',
  },
]

export default function HomeAdmin() {
  const classes = useStyles()
  return (
    <Grid container spacing={4} className={classes.grid}>
      {adminLinks.map((item, key) => (
        <Grid item xs={4} key={key}>
          <Link to={item.to}>
            <Paper className={classes.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={isMobile ? 'column-reverse' : 'row'}
                height={80}
              >
                <Text
                  text={
                    isMobile ? truncate(item.title, { length: 20 }) : item.title
                  }
                  variant={isMobile ? 'subtitle2' : 'h4'}
                  align="center"
                />
                {isMobile && <EcSpaceVertical size={4} />}
                <Icon className={item.icon} fontSize="large" />
              </Box>
            </Paper>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}
