import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AvailabilitySlotService,
  CreateAppointmentDto,
  UpdateAppointmentDto,
} from '../../services/api'
import { Actions } from '../actions/actions'

export const createAppointment = createAsyncThunk(
  Actions.CreateAppointment,
  async (appointment: CreateAppointmentDto, { rejectWithValue }) => {
    try {
      return AvailabilitySlotService.createAppointment({
        body: appointment,
      })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const updateAppointment = createAsyncThunk(
  Actions.UpdateAppointment,
  async (
    { id, appointment }: { appointment: UpdateAppointmentDto; id: number },
    { rejectWithValue },
  ) => {
    try {
      return AvailabilitySlotService.updateAppointment({
        body: appointment,
        id,
      })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
export const cancelAppointment = createAsyncThunk(
  Actions.DeleteAppointment,
  async (args: { id: number; appointmentId: number }, { rejectWithValue }) => {
    try {
      return AvailabilitySlotService.cancelAppointment(args)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)
