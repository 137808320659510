import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExamDto } from '../../services/api'
import { findExams } from '../thunks/examThunk'

export interface ExamState {
  exams: {
    totalCount: number
    datas: ExamDto[]
  }
}

const initialState: ExamState = {
  exams: {
    totalCount: 0,
    datas: [],
  },
}

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      findExams.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.exams = payload
      },
    )
  },
})

export default examSlice.reducer
