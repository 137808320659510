import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MemberDto } from '../../services/api'
import { findAllMembers } from '../thunks/memberThunk'

export interface memberState {
  members: {
    totalCount: number
    datas: MemberDto[]
  }
}

const initialState: memberState = {
  members: {
    totalCount: 0,
    datas: [],
  },
}

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      findAllMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.members = payload
      },
    )
  },
})

export default memberSlice.reducer
