import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './authReducer'
import alertReducer from './alertReducer'
import patientReducer from './patientReducer'
import loadingReducer from './loadingReducer'
import doctorReducer from './doctorReducer'
import studyReducer from './studyReducer'
import logReducer from './logReducer'
import siteReducer from './siteReducer'
import clientReducer from './clientReducer'
import appointementReducer from './appointmentReducer'
import examRoomReducer from './examRoomReducer'
import availabiltySlotReducer from './availabilitySlotReducer'
import examTypeReducer from './examTypeReducer'
import examReducer from './examReducer'
import memberReducer from './memberReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  patient: patientReducer,
  appointement: appointementReducer,
  doctor: doctorReducer,
  study: studyReducer,
  site: siteReducer,
  client: clientReducer,
  alert: alertReducer,
  loading: loadingReducer,
  log: logReducer,
  examRoom: examRoomReducer,
  availabilitySlots: availabiltySlotReducer,
  examType: examTypeReducer,
  exam: examReducer,
  member: memberReducer,
})

export default rootReducer
