import { createAsyncThunk } from '@reduxjs/toolkit'
import { RoomService } from '@services/api'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

export const findAvailableExams = createAsyncThunk(
  'examRoom/find-available-exams',
  (
    query: {
      id: number
      doctorId?: number
      examTypes: number[]
      bodyParts: string[]
    },
    { dispatch },
  ) => {
    try {
      return RoomService.findAvailableExams(query)
    } catch (e: any) {
      if (e.response) {
        dispatch(
          enqueueSnackbar({ type: 'error', message: e.response.data.message }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
      throw e
    }
  },
)
