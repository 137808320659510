import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AvailabilitySlotDto, AvailabilitySlotItem } from '@services/api'
import {
  createAvailabilitySlot,
  updateAvailabilitySlot,
  updateAvailabilitySlotItems,
} from '@state/thunks/availabilitySlotThunk'
import {
  cancelAppointment,
  createAppointment,
} from '../thunks/appointmentThunk'

export interface AvailabilitySlotState {
  data: AvailabilitySlotDto[]
  paging: {
    from: Date
    to: Date
  }
  newAvailabilityDialog: {
    isVisible?: boolean
    data: Partial<AvailabilitySlotDto>
  }
  contextMenu: {
    isVisible: boolean
    data: {
      x: number
      y: number
    }
  }
  selectedAvailabilitySlot: AvailabilitySlotDto | null

  selectedAvailabilitySlotItems: AvailabilitySlotItem[]
  isUpdateSlotItemsVisible: boolean
  isCloseItemDialogVisible: boolean
}

const initialState: AvailabilitySlotState = {
  data: [],
  paging: {
    from: new Date(),
    to: new Date(),
  },
  newAvailabilityDialog: {
    data: {},
    isVisible: false,
  },
  contextMenu: {
    data: {
      x: 0,
      y: 0,
    },
    isVisible: false,
  },
  selectedAvailabilitySlotItems: [],
  isUpdateSlotItemsVisible: false,
  isCloseItemDialogVisible: false,
  selectedAvailabilitySlot: null,
}

export const AvailabiltySlotReducer = createSlice({
  name: 'availabilitySlot',
  initialState,
  reducers: {
    setAvailabilitySlots: (
      state,
      action: PayloadAction<AvailabilitySlotDto[]>,
    ) => {
      state.data = action.payload
    },
    addAvailabilitySlots: (
      state,
      action: PayloadAction<AvailabilitySlotDto[]>,
    ) => {
      state.data = state.data.concat(action.payload)
    },
    setAvailabilitySlot: (
      state,
      action: PayloadAction<AvailabilitySlotDto[]>,
    ) => {
      state.data = action.payload
    },
    openNewAvailabilityDialog: (
      state,
      action: PayloadAction<AvailabilitySlotState['newAvailabilityDialog']>,
    ) => {
      state.newAvailabilityDialog = {
        isVisible: true,
        data: action.payload.data,
      }
    },
    closeNewAvailabilityDialog: (state) => {
      state.newAvailabilityDialog = {
        isVisible: false,
        data: {},
      }
    },
    openContextMenu: (
      state,
      action: PayloadAction<{
        x: number
        y: number
      }>,
    ) => {
      state.contextMenu = {
        isVisible: true,
        data: { ...action.payload },
      }
    },
    closeContextMenu: (state) => {
      state.contextMenu = {
        isVisible: false,
        data: {
          x: 0,
          y: 0,
        },
      }
    },
    setSelectedItems: (
      state,
      action: PayloadAction<AvailabilitySlotItem[]>,
    ) => {
      state.selectedAvailabilitySlotItems = action.payload
    },
    setIsUpdateSlotItemsVisible: (state, action: PayloadAction<boolean>) => {
      state.isUpdateSlotItemsVisible = action.payload
    },
    setIsCloseItemDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.isCloseItemDialogVisible = action.payload
    },
    setSelectedAvailabilitySlot: (
      state,
      action: PayloadAction<AvailabilitySlotDto | null>,
    ) => {
      state.selectedAvailabilitySlot = action.payload
    },
  },
  extraReducers: {
    [createAvailabilitySlot.fulfilled.toString()]: (
      state,
      action: PayloadAction<AvailabilitySlotDto[]>,
    ) => {
      const createdSlots = action.payload.map((slot) => {
        slot.startDate = new Date(slot.startDate)
        slot.endDate = new Date(slot.endDate)
        return slot
      })
      state.data = [...state.data, ...createdSlots]
      state.newAvailabilityDialog = {
        ...state.newAvailabilityDialog,
        isVisible: false,
      }
    },
    [updateAvailabilitySlot.fulfilled.toString()]: (
      state,
      action: PayloadAction<AvailabilitySlotDto>,
    ) => {
      if (!action.payload) {
        return
      }
      const index = state.data.findIndex(
        (_slot) => _slot.id == action.payload.id,
      )

      if (index === -1) {
        return
      }

      state.data[index] = { ...action.payload }
    },
    [updateAvailabilitySlotItems.fulfilled.toString()]: (
      state,
      action: PayloadAction<AvailabilitySlotItem[]>,
    ) => {
      if (!action.payload) {
        return
      }

      const index = state.data.findIndex(
        (_slot) => _slot.id === state.selectedAvailabilitySlot?.id,
      )
      const availabilitySlot: AvailabilitySlotDto = state.data[index]
      availabilitySlot.items = availabilitySlot.items.map((_item) => {
        const updatedItem = action.payload.find(
          (_updatedItem) => _item.id === _updatedItem.id,
        )
        if (updatedItem) {
          return updatedItem
        }
        return _item
      })

      state.isUpdateSlotItemsVisible = false
      state.isCloseItemDialogVisible = false
      state.contextMenu.isVisible = false
    },
    [createAppointment.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<AvailabilitySlotDto>,
    ) => {
      const index = state.data.findIndex((av) => av.id === payload.id)
      if (index !== -1) {
        state.data[index] = payload
      }
    },
    [cancelAppointment.fulfilled.toString()]: (
      state,
      {
        payload,
      }: PayloadAction<{
        slotID: number
        slotItemId: number
        appointmentId: number
      }>,
    ) => {
      const slotIndex = state.data.findIndex((av) => av.id === payload.slotID)
      const itemIndex = state.data[slotIndex].items.findIndex(
        (it) => it.id === payload.slotItemId,
      )
      state.data[slotIndex].items[itemIndex].appointment = null
    },
  },
})

export const {
  setAvailabilitySlots,
  setAvailabilitySlot,
  addAvailabilitySlots,
  openNewAvailabilityDialog,
  closeNewAvailabilityDialog,
  openContextMenu,
  closeContextMenu,
  setSelectedItems,
  setIsUpdateSlotItemsVisible,
  setIsCloseItemDialogVisible,
  setSelectedAvailabilitySlot,
} = AvailabiltySlotReducer.actions

export default AvailabiltySlotReducer.reducer
