import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { SiteDto, SiteService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { SiteActions } from '../actions'
import {
  addSite,
  setSelected,
  setSites,
  updateSite,
} from '../reducers/siteReducer'

function* findAll() {
  try {
    const sites: PaginatedDto<SiteDto> = yield call(SiteService.findAll)
    yield put(setSites(sites))
    yield put({
      type: SiteActions.GET_SITES_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: SiteActions.GET_SITES_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    const site: SiteDto = yield call(SiteService.findOne, payload)
    yield put(setSelected(site))
    yield put({
      type: SiteActions.GET_SITE_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: SiteActions.GET_SITE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
function* create({ payload }: AnyAction) {
  try {
    const site: SiteDto = yield call(SiteService.create, payload)
    yield put(addSite(site))
    yield put({
      type: SiteActions.POST_SITE_SUCCESS,
      payload: { message: 'New site successfully added to site' },
    })
  } catch (e: any) {
    yield put({
      type: SiteActions.POST_SITE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    const siteDto: SiteDto = yield call(SiteService.update, payload)
    yield put(updateSite(siteDto))
    yield put({
      type: SiteActions.PUT_SITE_SUCCESS,
      payload: { message: 'Site successfully updated' },
    })
  } catch (e: any) {
    yield put({
      type: SiteActions.PUT_SITE_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* siteFlow() {
  yield all([
    takeLatest(SiteActions.GET_SITES_REQUEST, findAll),
    takeLatest(SiteActions.GET_SITE_REQUEST, findOne),
    takeLatest(SiteActions.POST_SITE_REQUEST, create),
    takeLatest(SiteActions.PUT_SITE_REQUEST, update),
  ])
}

const siteSagas = [fork(siteFlow)]

export default siteSagas
