import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { FinessDto, FinessService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { FinessActions } from '../actions'
import { setFiness, setOptions } from '../reducers/siteReducer'

function* findAll({ payload }: AnyAction) {
  try {
    const finess: PaginatedDto<FinessDto> = yield call(
      FinessService.findAll,
      payload,
    )
    yield put(setFiness(finess))
    yield put({ type: FinessActions.GET_FINESS_SUCCESS })
  } catch (e: any) {
    yield put({
      type: FinessActions.GET_FINESS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

// function* autocomplete({ payload }: AnyAction) {
//   try {
//     const { datas }: PaginatedDto<FinessDto> = yield call(
//       FinessService.findAll,
//       payload,
//     )
//     yield put(setOptions(datas))
//     yield put({ type: FinessActions.GET_AUTOCOMPLETE_FINESS_SUCCESS })
//   } catch (e: any) {
//     yield put({
//       type: FinessActions.GET_AUTOCOMPLETE_FINESS_ERROR,
//       payload: { message: e.response.data.message },
//     })
//   }
// }

function* autocompleteFiness({ payload }: AnyAction) {
  try {
    // yield call(delay, 500)
    const datas: any[] = yield call(FinessService.autocomplete, payload)
    console.log('FINESS datas and playload', payload, datas)
    console.log(datas.length)

    yield put(setOptions(datas))
    yield put({ type: FinessActions.GET_AUTOCOMPLETE_FINESS_SUCCESS })
  } catch (e: any) {
    yield put({
      type: FinessActions.GET_AUTOCOMPLETE_FINESS_ERROR,
      payload: { message: e?.response?.data.message },
    })
  }
}

function* create(action: AnyAction) {
  try {
    yield call(FinessService.createAll, action.payload)
    // yield put({ type: SET_SNACK_SUCCESS, payload: {message: data.message}})
  } catch (error: any) {
    yield put({
      type: FinessActions.POST_FINESS_REQUEST,
      payload: { message: error.response.data.message, error },
    })
  }
}

export function* FinessFlow() {
  yield all([
    takeLatest(FinessActions.POST_FINESS_REQUEST, create),
    takeLatest(
      FinessActions.GET_AUTOCOMPLETE_FINESS_REQUEST,
      autocompleteFiness,
    ),
    takeLatest(FinessActions.GET_FINESS_REQUEST, findAll),
  ])
}

const finessSagas = [fork(FinessFlow)]

export default finessSagas

// function setOptions(datas: FinessDto[]): any {
//   throw new Error('Function not implemented.')
// }
