import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LogDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type logState = {
  logs: {
    totalCount: number
    datas: LogDto[]
  }
}

const initialState: logState = {
  logs: {
    totalCount: 0,
    datas: [],
  },
}

const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    setLogs: (state, action: PayloadAction<PaginatedDto<LogDto>>) => {
      state.logs = action.payload
    },
    removeLogs: (state) => {
      state.logs = initialState.logs
    },

    cleanLog: () => initialState,
  },
})

export const { setLogs, removeLogs, cleanLog } = logSlice.actions

export default logSlice.reducer
