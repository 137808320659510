import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Footer from '@components/Footer'
import { makeStyles } from '@material-ui/core'
import { RootState } from '@state/store'

const useStyles = makeStyles(() => ({
  section: {
    flexGrow: 1,
  },
  userShareGrid: {
    width: '100%',
    padding: '10px',
    paddingTop: '0px',
  },

  authLayoutMain: {
    minHeight: '94.99vh',
  },
}))

export default function AuthLayout({ children, ...props }: any) {
  const classes = useStyles()
  const isAuth = useSelector(({ auth }: RootState) => auth.isAuth)
  if (isAuth)
    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  return (
    <>
      <main className={classes.authLayoutMain}>
        {children}
        {/* style height 5 vh */}
        <Footer />
      </main>
    </>
  )
}
