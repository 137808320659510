import React from 'react'
import {
  Icon,
  IconProps,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  makeStyles,
} from '@material-ui/core'
import { NavLink, LinkProps } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

interface ListItemLinkProps {
  IconProps: IconProps
  ItemTextProps?: ListItemTextProps
  text: string
  to: string
  format?: boolean
}

const useStyles = makeStyles(() => ({
  activeLink: {
    borderRight: '4px solid #ff586b',
  },
  link: {
    color: 'white',
  },
  icon: {
    padding: 8,
    minWidth: 40,
  },
}))

export function ListItemLink({
  text,
  to,
  IconProps,
  ItemTextProps,
  format = false,
}: ListItemLinkProps) {
  const classes = useStyles()
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
        <NavLink
          to={to}
          ref={ref}
          {...itemProps}
          activeClassName={classes.activeLink}
        />
      )),
    [classes.activeLink, to],
  )

  return (
    <ListItem button component={renderLink} className={classes.link}>
      <ListItemIcon className={classes.icon}>
        <Icon {...IconProps} fontSize="small" />
      </ListItemIcon>
      <ListItemText
        {...ItemTextProps}
        primary={format ? <FormattedMessage id={text} /> : text}
      />
    </ListItem>
  )
}
