import { AnyAction } from 'redux'

export interface LoadingState {
  actionType: any
  isLoading: boolean
  [requestName: string]: boolean
}

const initialState: LoadingState = {
  actionType: true,
  isLoading: false,
}

export const loadingReducer = (
  state: LoadingState = initialState,
  { type }: AnyAction,
): LoadingState => {
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type)
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST',
  }
}
export default loadingReducer
