import { Suspense, lazy } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import { Spinner } from '@components/loadings'

import AppLayout from '../layouts/appLayout'
import AuthLayout from '../layouts/authLayout'
import history from '@services/history'

const routes = [
  // CLIENT VIEW
  // {
  //   path: '/',
  //   Component: lazy(() => import('./app/Home')),
  //   Layout: AppLayout,
  // },

  // SCHEDULES
  {
    path: '/',
    Component: lazy(() => import('./app/schedule/Schedule')),
    Layout: AppLayout,
  },

  {
    path: '/a/patientappointment',
    Component: lazy(() => import('./app/patientAppointment/Appointment')),
    Layout: AppLayout,
  },

  {
    path: '/a/confirmappointment',
    Component: lazy(
      () => import('./app/patientAppointment/ConfirmAppointment'),
    ),
    Layout: AppLayout,
  },

  {
    path: '/sa/schedule',
    Component: lazy(() => import('./app/schedule/Schedule')),
    Layout: AppLayout,
  },

  {
    path: '/sa/patientappointment',
    Component: lazy(() => import('./app/patientAppointment/Appointment')),
    Layout: AppLayout,
  },

  {
    path: '/sa/confirmappointment',
    Component: lazy(
      () => import('./app/patientAppointment/ConfirmAppointment'),
    ),
    Layout: AppLayout,
  },
  // AUTH

  {
    path: '/auth/login',
    Component: lazy(() => import('./auth/Login')),
    Layout: AuthLayout,
  },

  {
    path: '/auth/forgotten-password',
    Component: lazy(() => import('./auth/ForgottenPassword')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('./auth/ResetPassword')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('./auth/Register')),
    Layout: AuthLayout,
  },
  {
    path: '/auth/verification-mail',
    Component: lazy(() => import('./auth/VerificationEmail')),
    Layout: AuthLayout,
  },
  {
    path: '*',
    Component: lazy(() => import('./auth/NotFound')),
    Layout: AuthLayout,
  },
]

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(({ path, Component, Layout }, index) => (
          <Route
            exact
            path={path}
            key={index}
            render={(props) => (
              <Layout {...props}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </Layout>
            )}
          />
        ))}
      </Switch>
    </Router>
  )
}
