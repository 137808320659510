import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Exam, ExamRoom } from '@services/api'
import { findAvailableExams } from '@state/thunks/examRoomThunk'

interface RoomState {
  rooms: ExamRoom[]
  error: string | null
  selectedExamRoom: ExamRoom | null
  availableExams: Exam[]
}

const initialState: RoomState = {
  rooms: [],
  error: null,
  selectedExamRoom: null,
  availableExams: [],
}

const examRoomSlice = createSlice({
  name: 'examRoom',
  initialState,
  reducers: {
    setExamRooms: (state, action: PayloadAction<ExamRoom[]>) => {
      state.rooms = action.payload
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
    setSelectedExamRoom: (state, action: PayloadAction<number | null>) => {
      state.selectedExamRoom = action.payload
        ? state.rooms.find((_room) => _room.id === action.payload) || null
        : null
    },
  },
  extraReducers: {
    [findAvailableExams.fulfilled.toString()]: (
      state,
      action: PayloadAction<Exam[]>,
    ) => {
      state.availableExams = action.payload
    },
  },
})
export const { setExamRooms, setError, setSelectedExamRoom } =
  examRoomSlice.actions

export default examRoomSlice.reducer
