import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { ExamRoom, RoomService } from '@services/api'
import { ExamRoomActions } from '../actions'
import { setError, setExamRooms } from '../reducers/examRoomReducer'

export function* findAll() {
  try {
    const examRooms: ExamRoom[] = yield call(RoomService.findAllExamRoom)
    yield put(setExamRooms(examRooms))
  } catch (e: any) {
    const error = e as Error
    yield put(setError(error.message))
  }
}

export function* examRoomFlow() {
  yield all([takeLatest(ExamRoomActions.FIND_ALL, findAll)])
}

const examRoomSagas = [fork(examRoomFlow)]

export default examRoomSagas
