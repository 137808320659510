import { Link } from 'react-router-dom'

import { Container, Divider, Grid, makeStyles, Theme } from '@material-ui/core'

import Text from './Text'

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    display: 'flex',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
  },

  fContainerGrid: {
    height: '5vh',
    padding: 10,
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <Container maxWidth="sm" component="footer" className="ec-footer">
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        className={classes.fContainerGrid}
      >
        <Link to="/privacy-policy">
          <Text text="privacy.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="copyright.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="about.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/terms">
          <Text text="terms.title" align="center" format />
        </Link>
        <Divider orientation="vertical" flexItem />
        <Link to="/privacy-policy">
          <Text text="contact-us.title" align="center" format />
        </Link>
      </Grid>
    </Container>
  )
}
