import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { ClientDto, ClientService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { ClientActions } from '../actions'
import { addClient, setClients, setSelected } from '../reducers/clientReducer'

function* findAll() {
  try {
    const clients: PaginatedDto<ClientDto> = yield call(ClientService.findAll)
    yield put(setClients(clients))
    yield put({
      type: ClientActions.GET_CLIENTS_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: ClientActions.GET_CLIENTS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    const client: ClientDto = yield call(ClientService.findOne, payload)
    yield put(setSelected(client))
    yield put({
      type: ClientActions.GET_CLIENT_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: ClientActions.GET_CLIENT_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
function* create({ payload }: AnyAction) {
  try {
    const client: ClientDto = yield call(ClientService.create, payload)
    yield put(addClient(client))
    yield put({
      type: ClientActions.POST_CLIENT_SUCCESS,
      payload: { message: 'New client successfully added' },
    })
  } catch (e: any) {
    yield put({
      type: ClientActions.POST_CLIENT_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    const client: ClientDto = yield call(ClientService.update, payload)
    yield put(setSelected(client))
    yield put({
      type: ClientActions.PUT_CLIENT_SUCCESS,
      payload: { message: 'Client successfully updated' },
    })
  } catch (e: any) {
    yield put({
      type: ClientActions.PUT_CLIENT_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

export function* clientFlow() {
  yield all([
    takeLatest(ClientActions.GET_CLIENTS_REQUEST, findAll),
    takeLatest(ClientActions.GET_CLIENT_REQUEST, findOne),
    takeLatest(ClientActions.POST_CLIENT_REQUEST, create),
    takeLatest(ClientActions.PUT_CLIENT_REQUEST, update),
  ])
}

const clientSagas = [fork(clientFlow)]

export default clientSagas
