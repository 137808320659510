import { createAsyncThunk } from '@reduxjs/toolkit'
import { DoctorService, ListQueryDto } from '@services/api'
import { enqueueSnackbar } from '../reducers/alertReducer'

export const findDoctors = createAsyncThunk(
  'doctor/find-all',
  (query: ListQueryDto, { dispatch }) => {
    try {
      return DoctorService.findAll(query)
    } catch (e: any) {
      if (e.response) {
        dispatch(
          enqueueSnackbar({ type: 'error', message: e.response.data.message }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
      throw e
    }
  },
)
