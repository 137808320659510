import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { PatientDto, PatientService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { PatientActions } from '../actions'
import { setPatients } from '../reducers/patientReducer'

function* findAll() {
  try {
    const patients: PaginatedDto<PatientDto> = yield call(
      PatientService.findAll,
    )
    yield put(setPatients(patients))
    yield put({
      type: PatientActions.GET_PATIENTS_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: PatientActions.GET_PATIENTS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* findOne({ payload }: AnyAction) {
  try {
    yield call(PatientService.findOne, payload)
    yield put({
      type: PatientActions.GET_PATIENT_SUCCESS,
    })
  } catch (e: any) {
    yield put({
      type: PatientActions.GET_PATIENT_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* update({ payload }: AnyAction) {
  try {
    yield call(PatientService.update, payload)
    yield put({
      type: PatientActions.PUT_PATIENT_SUCCESS,
      payload: { message: 'Patient successfully updated' },
    })
  } catch (e: any) {
    yield put({
      type: PatientActions.PUT_PATIENT_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* updateReferringDoctor({ payload }: AnyAction) {
  try {
    yield call(PatientService.updateReferringDoctor, payload)
    yield put({
      type: PatientActions.PUT_REFERRING_DOCTOR_SUCCESS,
      payload: { message: 'Patient successfully updated' },
    })
  } catch (e: any) {
    yield put({
      type: PatientActions.PUT_REFERRING_DOCTOR_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}
function* openViewer({ payload }: AnyAction) {
  try {
    const { externalId } = payload
    const url = `${process.env.REACT_APP_PATIENT_VIEWER_URL}&action=view&StudyInstanceUID=${externalId}`
    const win = window.open(url, '_blank')
    if (win) {
      win.focus()
    } else {
      throw new Error('OPEN_VIEWER_ERROR')
    }
  } catch (e: any) {
    yield put({
      type: PatientActions.OPEN_VIEWER_ERROR,
      message: 'default.msg.error',
    })
  }
}

export function* patientFlow() {
  yield all([
    takeLatest(PatientActions.GET_PATIENTS_REQUEST, findAll),
    takeLatest(PatientActions.GET_PATIENT_REQUEST, findOne),
    takeLatest(PatientActions.PUT_PATIENT_REQUEST, update),
    takeLatest(
      PatientActions.PUT_REFERRING_DOCTOR_REQUEST,
      updateReferringDoctor,
    ),
    takeLatest(PatientActions.OPEN_VIEWER_REQUEST, openViewer),
  ])
}

const patientSagas = [fork(patientFlow)]

export default patientSagas
