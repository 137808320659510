import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  AvailabilitySlotItem,
  AvailabilitySlotService,
  CreateAvailabilitySlotDto,
  FindAvailabilityDto,
  UpdateAvailabilitySlotDto,
} from '@services/api'
import { enqueueSnackbar } from '../reducers/alertReducer'
import { setAvailabilitySlot } from '../reducers/availabilitySlotReducer'

export const createAvailabilitySlot = createAsyncThunk(
  'availablitySlot/create',
  async (data: CreateAvailabilitySlotDto, { dispatch }) => {
    try {
      const createdSlot = await AvailabilitySlotService.create({ body: data })
      return createdSlot
    } catch (e: any) {
      if (e.response) {
        dispatch(
          enqueueSnackbar({ type: 'error', message: e.response.data.message }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
      throw e
    }
  },
)
export const findAvailabilitySlots = createAsyncThunk(
  'availabilitySlot/find',
  async (dto: FindAvailabilityDto, { dispatch }) => {
    try {
      const availabilities = await AvailabilitySlotService.find({ dto })
      dispatch(
        setAvailabilitySlot(
          availabilities.map((av) => {
            return av
          }),
        ),
      )
    } catch (e: any) {
      const error = e as { response: any }
      if (error.response) {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: error.response.data.message,
          }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
    }
  },
)
export const updateAvailabilitySlot = createAsyncThunk(
  'availabilitySlot/setAsEmergency',
  async (dto: UpdateAvailabilitySlotDto, { dispatch }) => {
    try {
      return AvailabilitySlotService.updateAvailabilitySlot(dto)
    } catch (e: any) {
      const error = e as { response: any }
      if (error.response) {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: error.response.data.message,
          }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
    }
  },
)
export const updateAvailabilitySlotItems = createAsyncThunk(
  'availabilitySlot/update-availability-slot',
  async (dto: AvailabilitySlotItem[], { dispatch }) => {
    try {
      return AvailabilitySlotService.updateAvailabilitySlotItems(dto)
    } catch (e: any) {
      const error = e as { response: any }
      if (error.response) {
        dispatch(
          enqueueSnackbar({
            type: 'error',
            message: error.response.data.message,
          }),
        )
      } else {
        dispatch(enqueueSnackbar({ type: 'error', message: 'Network Error' }))
      }
    }
  },
)
