/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios'
import qs from 'qs'

export interface IRequestOptions {
  headers?: any
  baseURL?: string
  responseType?: string
}

export interface IRequestConfig {
  method?: any
  headers?: any
  url?: any
  data?: any
  params?: any
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance
}

// Add default options
export const serviceOptions: ServiceOptions = {}

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void,
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  } else {
    throw new Error('please inject yourself instance like axios  ')
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any,
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url }
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  }
  return configs
}

const basePath = ''

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[]
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[]
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number
  items?: T[]
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number
  items?: T[]
}

// customer definition
// empty

export class MirthService {
  /**
   *
   */
  static createOrder(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ORM'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createObservation(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ORU'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static receiveInstance(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/OMG'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static mergePatient(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ADT/A40'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static registerPatient(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ADT/A04'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePatient(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/ADT/A08'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createAppointment(
    params: {
      /**  */
      externalId: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mirth/SIU/S12'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { externalId: params['externalId'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class PatientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdatePatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PatientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateReferringDoctor(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdatePatientReferringDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/patient/{id}/referring-doctor'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class DoctorService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<DoctorDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateDoctorDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Doctor> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/doctor/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class UserService {
  /**
   *
   */
  static getPhoneVerificationCheck(
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static sendPhoneVerification(
    params: {
      /** requestBody */
      body?: CreatePhoneAuthMethod
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/check'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static phoneVerify(
    params: {
      /** requestBody */
      body?: PhoneVerifyRequest
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/phone/verify'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findMyAccount(options: IRequestOptions = {}): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class MailService {
  /**
   *
   */
  static contact(
    params: {
      /**  */
      from: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/mail/contact'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )
      configs.params = { from: params['from'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class StudyService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findMyStudies(options: IRequestOptions = {}): Promise<StudyDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/my-studies'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<StudyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static getTicketNumber(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/ticket-number'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/download'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static shareStudy(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: CreateSharedStudyDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SharedStudyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/share'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findSharesByStudyId(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Study> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/share'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static unshareStudy(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/unshare'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uploadAdvice(
    params: {
      /**  */
      id: string
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/advice'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )

      let data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static deleteAdvice(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/study/{id}/advice'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class GedService {
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/download'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static uploadFile(
    params: {
      /**  */
      id: string
      /**  */
      type: string
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<GedFileDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/ged/files/{id}/upload'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )
      configs.params = { type: params['type'] }
      let data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class LogService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
      /**  */
      startDate: string
      /**  */
      endDate: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/log'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
        startDate: params['startDate'],
        endDate: params['endDate'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class AuthService {
  /**
   *
   */
  static getCaptcha(options: IRequestOptions = {}): Promise<CapchaDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/captcha'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/login'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/logout'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static isValidFirstConnection(
    params: {
      /**  */
      accessionNumber: string
      /**  */
      birthDate: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Patient> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        accessionNumber: params['accessionNumber'],
        birthDate: params['birthDate'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createUserFirstConnection(
    params: {
      /** requestBody */
      body?: LocalUserRegisterPatientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<LoginResDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/patient/first-connection'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createLocalUserFromEmail(
    params: {
      /** requestBody */
      body?: LocalUserRegisterFromMailDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/verify-mail'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static forgottenPassword(
    params: {
      /**  */
      email: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/forgotten-password'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { email: params['email'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserResetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/reset-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number
      /** requestBody */
      body?: LocalUserUpdatePasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/{id}/update-password'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateEmail(
    params: {
      /** requestBody */
      body?: LocalUserForgetPasswordDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/local/update-email'

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ApiKeyService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateApiKeyDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ApiKeyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ApiKeyDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static lock(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}/lock'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static unLockApiKey(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api-key/{id}/unlock'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class SiteService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSiteDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateSiteDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<UpdateSiteDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/site/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ClientService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateClientDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ClientDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/client/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class FinessService {
  /**
   *
   */
  static createAll(
    params: {
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess'

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )

      let data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static autocomplete(
    params: {
      /**  */
      finessNumber: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<FinessDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess/autocomplete'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { finessNumber: params['finessNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      finessNumber: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<FinessDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/finess/{finessNumber}'
      url = url.replace('{finessNumber}', params['finessNumber'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class AdminService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<AdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<AdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class SuperAdminService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateSuperAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SuperAdminDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SuperAdmin> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateSuperAdminDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/super-admin/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class RppsService {
  /**
   *
   */
  static createAll(
    params: {
      /**  */
      file: any
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps'

      const configs: IRequestConfig = getConfigs(
        'post',
        'multipart/form-data',
        url,
        options,
      )

      let data = new FormData()
      if (params['file']) {
        if (
          Object.prototype.toString.call(params['file']) === '[object Array]'
        ) {
          for (const item of params['file']) {
            data.append('file', item as any)
          }
        } else {
          data.append('file', params['file'] as any)
        }
      }

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      filters?: Filter[]
      /**  */
      limit: number
      /**  */
      skip: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        filters: params['filters'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static autocomplete(
    params: {
      /**  */
      rppsNumber: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RppsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps/autocomplete'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { rppsNumber: params['rppsNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      rppsNumber: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RppsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/rpps/{rppsNumber}'
      url = url.replace('{rppsNumber}', params['rppsNumber'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ViewerService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ViewerDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateViewerDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ViewerDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ViewerDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateViewerDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Viewer> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/viewer/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class WaitingRoomService {
  /**
   *
   */
  static createExamRoom(
    params: {
      /** requestBody */
      body?: CreateWaitingRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<WaitingRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findWaitingRoom(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/waiting-room/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class RoomService {
  /**
   *
   */
  static createExamRoom(
    params: {
      /** requestBody */
      body?: CreateExamRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllExamRoom(options: IRequestOptions = {}): Promise<ExamRoom[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneExamRoom(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateExamRoom(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateExamRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removeExamRoom(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAvailableExams(
    params: {
      /**  */
      id: number
      doctorId?: number
      examTypes?: number[]
      bodyParts?: string[]
    } = {} as any,
    options: IRequestOptions = {},
  ) {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/exam/{id}/available-exams?'
      url = url.replace('{id}', params['id'] + '')
      let filterExists = false
      if (params.doctorId) {
        url += `doctorId=${params.doctorId}`
        filterExists = true
      }
      if (params.examTypes) {
        if (filterExists) {
          url += '&'
        }
        params.examTypes.forEach((_examType) => {
          url += `examTypes[]=${_examType}&`
        })
        if (!filterExists) {
          filterExists = true
        }
      }
      if (params.bodyParts) {
        if (filterExists) {
          url += '&'
        }
        params.bodyParts.forEach((_bodyPart) => {
          url += `bodyParts[]=${_bodyPart}&`
        })
        if (!filterExists) {
          filterExists = true
        }
      }

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createPrepRoom(
    params: {
      /** requestBody */
      body?: CreatePrepRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<PrepRoom> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllPrepRoom(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOnePrepRoom(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updatePrepRoom(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdatePrepRoomDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static removePrepRoom(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/room/prep/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ReceptionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Reception> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateReceptionDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/reception/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class OfficeService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Office> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateOfficeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/office/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export class ExamService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateExamDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<Exam> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAll(
    query: { examTypes?: number[]; examRoomId?: number } = {},
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam'
      if (query) {
        url += `?${qs.stringify(query)}`
      }

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateExamDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static createType(
    params: {
      /** requestBody */
      body?: CreateExamTypeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<ExamType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findAllTypes(
    params: { examRoomId: number },
    options: IRequestOptions = {},
  ): Promise<ExamType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type?examRoomId=' + params.examRoomId

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static findOneType(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  /**
   *
   */
  static updateType(
    params: {
      /**  */
      id: string
      /** requestBody */
      body?: UpdateExamTypeDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  static;
  /**
   *
   */
  removeType(
    params: {
      /**  */
      id: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/exam/type/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
export class AvailabilitySlotService {
  static create(
    params: {
      body: CreateAvailabilitySlotDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/availability-slot'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  static createAppointment(
    params: {
      body: CreateAppointmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = basePath + '/availability-slot/{id}/appointments'

      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  static updateAppointment(
    params: {
      id: number
      body: UpdateAppointmentDto
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/availability-slot/appointments/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      let data = params.body

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
  static cancelAppointment(
    params: {
      id: number
      appointmentId: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url =
        basePath +
        '/availability-slot/{id}/appointments/' +
        params.appointmentId
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        url,
        options,
      )

      axios(configs, resolve, reject)
    })
  }
  static find(
    params: {
      dto: FindAvailabilityDto
    },
    options: IRequestOptions = {},
  ): Promise<AvailabilitySlotDto[]> {
    return new Promise((resolve, reject) => {
      const url =
        basePath +
        `availability-slot?startDate=${params.dto.startDate}&endDate=${params.dto.endDate}`
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      axios(configs, resolve, reject)
    })
  }
  static updateAvailabilitySlot(
    body: UpdateAvailabilitySlotDto,
    options: IRequestOptions = {},
  ): Promise<AvailabilitySlotDto> {
    return new Promise((resolve, reject) => {
      const url = basePath + `availability-slot/${body.id}`
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      configs.data = body
      axios(configs, resolve, reject)
    })
  }

  static updateAvailabilitySlotItems(
    body: AvailabilitySlotItem[],
    options: IRequestOptions = {},
  ): Promise<AvailabilitySlotDto> {
    return new Promise((resolve, reject) => {
      const url = basePath + `availability-slot/update-items`
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        url,
        options,
      )

      configs.data = body
      axios(configs, resolve, reject)
    })
  }
}

export class DirectoryService {
  /**
   *
   */
  static autocompleteByName(
    params: {
      /**  */
      name: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<RppsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/name'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { name: params['name'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static autocompleteByRpps(
    params: {
      /**  */
      rppsNumber: string
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<SavoirFaireDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/directory/autocomplete/rpps'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = { rppsNumber: params['rppsNumber'] }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
export class MemberService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      search?: string
      /**  */
      limit?: number
      /**  */
      skip?: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<any | null> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member'

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )
      configs.params = {
        search: params['search'],
        limit: params['limit'],
        skip: params['skip'],
      }
      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }

  /**
   *
   */
  static findOne(
    params: {
      /**  */
      id: number
    } = {} as any,
    options: IRequestOptions = {},
  ): Promise<MemberDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/member/{id}'
      url = url.replace('{id}', params['id'] + '')

      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        url,
        options,
      )

      let data = null

      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}

export interface MemberDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: MemberRoleEnum

  /**  */
  clientId: number

  /**  */
  client: Client
}

export enum MemberRoleEnum {
  'health-executive' = 'health-executive',
  'executive-director' = 'executive-director',
  'finance-director' = 'finance-director',
  'accountant' = 'accountant',
  'computer-technician' = 'computer-technician',
  'security' = 'security',
  'rssi' = 'rssi',
  'dsi' = 'dsi',
  'secretary' = 'secretary',
  'radiographer' = 'radiographer',
  'service-provider' = 'service-provider',
  'it-provider' = 'it-provider',
  'manager' = 'manager',
  'radiologist' = 'radiologist',
  'head-of-emergency' = 'head-of-emergency',
  'head-of-radiology' = 'head-of-radiology',
  'biomedical-engineer' = 'biomedical-engineer',
  'quality-manager' = 'quality-manager',
  'other' = 'other',
}

export interface CreateAvailabilitySlotDto extends AppointmentBase {
  /** */
  title: string
  /** */
  doctorId: number
  /** */
  examRoomId: number
  /** */
  bodyPart?: EnumExamBodyPart
  /** */
  isInjection: boolean
  /** */
  isEmergency: boolean
  /** */
  isFastRequired: boolean
  /** */
  isMedicationRequired: boolean
  /** */
  isShowerRequired: boolean
  /** */
  recurrence?: number
  /** */
  procedureTime: number
  /** */
  types?: number[]
}

export interface LocalAuthMethod {
  /**  */
  email: string

  /**  */
  password: string

  /**  */
  previousPasswords?: string[]

  /**  */
  verificationUUID?: string

  /**  */
  resetPasswordUUID?: string

  /**  */
  endLockout?: Date

  /**  */
  failedAttempt?: number
}

export interface CpsAuthMethod {
  /**  */
  email: string

  /**  */
  password: string
}

export interface PhoneAuthMethod {
  /**  */
  phoneNumber: string

  /**  */
  phoneVerificationCode?: string

  /**  */
  isVerified: boolean
}

export interface AuthMethod {
  /**  */
  local: LocalAuthMethod

  /**  */
  cps?: CpsAuthMethod

  /**  */
  phone?: PhoneAuthMethod
}

export interface Account {
  /**  */
  role: AccountRoleEnum

  /**  */
  id: number

  /**  */
  authMethod: AuthMethod
}

export interface Admin {
  rppsNumber: number
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: EnumAdminRole

  /**  */
  clientId: number

  /**  */
  client: Client
}

export interface SecurityPreferences {
  /**  */
  failureNumber: number

  /**  */
  lockoutPeriod: number

  /**  */
  inactivityTimeout: number

  /**  */
  twoFAMandatory: boolean

  /**  */
  twoFAMethod: EnumSecurityPreferencesTwoFAMethod

  /**  */
  passwordChangesMandatory: boolean

  /**  */
  passwordChangesPeriod: number

  /**  */
  samePasswordAllowed: boolean
}

export interface Viewer {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  brand: string

  /**  */
  nuclear: boolean

  /**  */
  measurement: boolean

  /**  */
  dicom: boolean

  /**  */
  CE: boolean

  /**  */
  gsps: boolean

  /**  */
  sr: boolean
}

export interface ViewerPreferences {
  /**  */
  patientViewer?: Viewer

  /**  */
  patientViewerId: number

  /**  */
  doctorViewer?: Viewer

  /**  */
  doctorViewerId: number

  /**  */
  radioViewer?: Viewer

  /**  */
  radioViewerId: number

  /**  */
  tomoViewer?: Viewer

  /**  */
  tomoViewerId: number

  /**  */
  nuclearViewer?: Viewer

  /**  */
  nuclearViewerId: number
}

export interface SharingPreferences {
  /**  */
  maxPermission: SharingPermissionEnum

  /**  */
  permission: SharingPermissionEnum

  /**  */
  ownershipAccept: boolean

  /**  */
  expiration: number
}

export interface ClientPreferences {
  /**  */
  security: SecurityPreferences

  /**  */
  viewers: ViewerPreferences

  /**  */
  sharing: SharingPreferences
}

export interface Client {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  admins: Admin[]

  /**  */
  preferences: ClientPreferences
}

export interface MedicalEquipment {
  /**  */
  id: number

  /**  */
  room: string

  /**  */
  location: string

  /**  */
  type: EnumMedicalEquipmentType

  /**  */
  model?: string

  /**  */
  manufacturer: string

  /**  */
  commissioningDate: Date

  /**  */
  dose: boolean

  /**  */
  shared: boolean

  /**  */
  active: boolean

  /**  */
  site: Site

  /**  */
  siteId: number
}

export interface ITEquipment {
  /**  */
  id: number

  /**  */
  type: string

  /**  */
  ip: string

  /**  */
  mac: string

  /**  */
  port: number

  /**  */
  infos: string

  /**  */
  site: Site
}

export interface Reception {
  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface WaitingRoom {
  /**  */
  reception: Reception

  /**  */
  receptionId: number

  /**  */
  examRooms: ExamRoom[]

  /**  */
  examRoomIds: number[]

  /**  */
  called: Visit[]

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface PrepRoom {
  /**  */
  status: EnumPrepRoomStatus

  /**  */
  isActive: boolean

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface ExamType {
  /**  */
  modality: EnumExamTypeModality

  /**  */
  sex: SexEnum

  /**  */
  ageRange: EnumExamTypeAgeRange

  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string
}

export interface Exam {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: EnumExamModality

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: EnumExamBodyPart

  /**  */
  ageRange: EnumExamAgeRange

  /**  */
  instruction: EnumExamInstruction

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  type: ExamType

  /**  */
  site: Site

  /**  */
  siteId: number
}

export interface ExamRoom {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  waitingRoom: WaitingRoom

  /**  */
  openingTime: Date

  /**  */
  recoveryTime: Date

  /**  */
  breakTime: Date

  /**  */
  closingTime: Date

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  exams: Exam[]

  /**  */
  examIds: number[]

  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  type: number

  /**  */
  processTime: number

  /**  */
  modality: ModalityEnum
}

export interface Office {
  /**  */
  id: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface Site {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  address: string

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  capacity: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  itEquipments: ITEquipment[]

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]
}

export interface Visit {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: Study[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  admitSource: EnumVisitAdmitSource

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean
}

export interface GedFile {
  /**  */
  id: number

  /**  */
  type: string

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}
export interface SavoirFaireDto {
  /**  */
  id: number

  /**  */
  rppsNumber: string

  /**  */
  lastName: string

  /**  */
  firstName: string

  /**  */
  categoryLabel: string

  /**  */
  categoryCode: string

  /**  */
  professionLabel: string

  /**  */
  professionCode: string

  /**  */
  skillLabel: string

  /**  */
  skillCode: string

  /**  */
  skillTypeLabel: string

  /**  */
  skillTypeCode: string

  /**  */
  createAt: number

  /**  */
  updateAt: number

  /**  */
  deletedAt: number
}

export interface Order {
  /**  */
  status: EnumOrderStatus

  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  ressource: GedFile
}

export interface Consent {
  /**  */
  status: EnumConsentStatus

  /**  */
  id: number

  /**  */
  ressource: GedFile
}

export interface Report {
  /**  */
  status: EnumReportStatus

  /**  */
  id: number

  /**  */
  ressource: number

  /**  */
  study: Study
}

export interface Study {
  /**  */
  status: EnumStudyStatus

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  attendingDoctor: Admin

  /**  */
  referringDoctor: Doctor

  /**  */
  prescription: Order

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  externalId: string

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: SharedStudy[]

  /**  */
  shareIds: number[]
}

export interface SharedStudy {
  /**  */
  permission: SharingPermissionEnum

  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  endDate: Date

  /**  */
  doctorId: number

  /**  */
  doctor: Doctor

  /**  */
  studyId: number

  /**  */
  study: Study
}

export interface Doctor {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  externalId: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  rppsNumber: number

  /**  */
  speciality: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  shares: SharedStudy[]
}

export interface Patient {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  externalId: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  referringDoctor: Doctor

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  clients: Client[]

  /**  */
  clientIds: number[]

  /**  */
  visits: Visit[]
}

export interface ExternalId {
  /**  */
  id: number

  /**  */
  value: string

  /**  */
  label: string

  /**  */
  description: string

  /**  */
  source: string

  /**  */
  patient: Patient
}

export interface DoctorDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  externalId: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  rppsNumber: number

  /**  */
  speciality: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  shares: SharedStudy[]
}

export interface PatientDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  ssn: string

  /**  */
  surName: string

  /**  */
  referringDoctor: DoctorDto

  /**  */
  externalId: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  birthDate: Date

  /**  */
  placeOfBirth: string

  /**  */
  legalPerson: string

  /**  */
  maritalStatus: string

  /**  */
  mergedDate: Date

  /**  */
  mergedPatient: Patient

  /**  */
  blackListedDoctors: Doctor[]

  /**  */
  ALD: boolean

  /**  */
  CSS: boolean

  /**  */
  CMU: boolean

  /**  */
  consent: Consent

  /**  */
  clients: Client[]

  /**  */
  clientIds: number[]

  /**  */
  visits: Visit[]
}

export interface UpdatePatientDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  title?: string

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  ssn?: string

  /**  */
  surName?: string

  /**  */
  externalId?: string

  /**  */
  externalIds?: ExternalId[]

  /**  */
  birthDate?: Date

  /**  */
  placeOfBirth?: string

  /**  */
  legalPerson?: string

  /**  */
  maritalStatus?: string

  /**  */
  mergedDate?: Date

  /**  */
  mergedPatient?: Patient

  /**  */
  referringDoctor?: Doctor

  /**  */
  blackListedDoctors?: Doctor[]

  /**  */
  ALD?: boolean

  /**  */
  CSS?: boolean

  /**  */
  CMU?: boolean

  /**  */
  consent?: Consent

  /**  */
  clients?: Client[]

  /**  */
  clientIds?: number[]

  /**  */
  visits?: Visit[]
}

export interface UpdatePatientReferringDoctorDto {
  /**  */
  shareOld: boolean

  /**  */
  shareNew: boolean

  /**  */
  rppsNumber: number
}

export interface CreateDoctorDto {
  /**  */
  id: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  externalIds: ExternalId[]

  /**  */
  rppsNumber: number

  /**  */
  speciality: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  profession: string

  /**  */
  externalId?: string

  /**  */
  shares: SharedStudy[]
}

export interface PaginatedDto {
  /**  */
  totalCount: number

  /**  */
  datas: string[]
}

export interface Filter {
  /**  */
  columnName: string

  /**  */
  operation?: string

  /**  */
  value?: string
}

export interface UpdateDoctorDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  title?: string

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  externalId?: string

  /**  */
  externalIds?: ExternalId[]

  /**  */
  rppsNumber?: number

  /**  */
  speciality?: string

  /**  */
  category?: string

  /**  */
  skill?: string

  /**  */
  profession?: string

  /**  */
  shares?: SharedStudy[]
}

export interface CreatePhoneAuthMethod {
  /**  */
  phoneNumber: string
}

export interface PhoneVerifyRequest {
  /**  */
  verificationCode: string
}

export interface StudyDto {
  hasImage: boolean
  /**  */
  status: EnumStudyDtoStatus

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  attendingDoctor: Admin

  /**  */
  referringDoctor: Doctor

  /**  */
  prescription: Order

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  externalId: string

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: SharedStudy[]

  /**  */
  shareIds: number[]
}

export interface CreateSharedStudyDto {
  /**  */
  permission: SharingPermissionEnum

  /**  */
  endDate: Date

  /**  */
  doctorId: number

  /**  */
  studyId: number
}

export interface SharedStudyDto {
  /**  */
  permission: SharingPermissionEnum

  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  endDate: Date

  /**  */
  doctorId: number

  /**  */
  doctor: Doctor

  /**  */
  studyId: number

  /**  */
  study: Study
}

export interface GedFileDto {
  /**  */
  id: number

  /**  */
  type: string

  /**  */
  relatedEntity: number

  /**  */
  filename: string

  /**  */
  mimeType: string
}

export interface LogDto {
  /**  */
  role: AccountRoleEnum

  /**  */
  action: EnumLogDtoAction

  /**  */
  _id?: string

  /**  */
  createdAt: Date

  /**  */
  id: number

  /**  */
  entityId: number
}

export interface CapchaDto {
  /**  */
  data: string

  /**  */
  code: string
}

export interface LoginDto {
  /**  */
  email: string

  /**  */
  password: string
}

export interface AccountDto {
  /**  */
  role: AccountRoleEnum

  /**  */
  email: string

  /**  */
  client: number

  /**  */
  phone: string

  /**  */
  security: SecurityPreferences

  /**  */
  id: number
}

export interface LoginResDto {
  /**  */
  token?: string

  /**  */
  user: AccountDto
}

export interface LocalUserRegisterPatientDto {
  /**  */
  email?: string

  /**  */
  password?: string

  /**  */
  accessionNumber: string

  /**  */
  birthDate: Date
}

export interface LocalUserRegisterFromMailDto {
  /**  */
  password: string

  /**  */
  userId: string

  /**  */
  verificationUUID: string
}

export interface LocalUserResetPasswordDto {
  /**  */
  newPassword: string

  /**  */
  resetPasswordUUID: string
}

export interface LocalUserUpdatePasswordDto {
  /**  */
  newPassword: string
}

export interface LocalUserForgetPasswordDto {
  /**  */
  email: string
}

export interface CreateApiKeyDto {
  /**  */
  site: Site

  /**  */
  expireAt: Date
}

export interface ApiKeyDto {
  /**  */
  id: number

  /**  */
  key: string

  /**  */
  site: Site

  /**  */
  expireAt: Date

  /**  */
  locked: boolean
}

export interface SiteDto {
  /**  */
  primary: boolean

  /**  */
  externalId: string

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  address: string

  /**  */
  id: number

  /**  */
  clientId: number

  /**  */
  client: Client

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  geocode: string

  /**  */
  capacity: number

  /**  */
  medicalEquipments: MedicalEquipment[]

  /**  */
  itEquipments: ITEquipment[]

  /**  */
  examRooms: ExamRoom[]

  /**  */
  prepRooms: PrepRoom[]

  /**  */
  offices: Office[]

  /**  */
  receptions: Reception[]

  /**  */
  waitingRooms: WaitingRoom[]

  /**  */
  exams: Exam[]
}

export interface CreateSiteDto {
  /**  */
  primary: boolean

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  address: string

  /**  */
  clientId: number

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  status: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string

  /**  */
  capacity: number
}

export interface UpdateSiteDto {
  /**  */
  primary?: boolean

  /**  */
  externalId?: string

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  address?: string

  /**  */
  id?: number

  /**  */
  clientId?: number

  /**  */
  client?: Client

  /**  */
  finessNumber?: string

  /**  */
  finessStructNumber?: string

  /**  */
  status?: string

  /**  */
  email?: string

  /**  */
  phoneNumber?: string

  /**  */
  fax?: string

  /**  */
  geocode?: string

  /**  */
  capacity?: number

  /**  */
  medicalEquipments?: MedicalEquipment[]

  /**  */
  itEquipments?: ITEquipment[]

  /**  */
  examRooms?: ExamRoom[]

  /**  */
  prepRooms?: PrepRoom[]

  /**  */
  offices?: Office[]

  /**  */
  receptions?: Reception[]

  /**  */
  waitingRooms?: WaitingRoom[]

  /**  */
  exams?: Exam[]
}

export interface ClientDto {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  id: number

  /**  */
  fax: string

  /**  */
  externalId: string

  /**  */
  logo: string

  /**  */
  sites: Site[]

  /**  */
  admins: Admin[]

  /**  */
  preferences: ClientPreferences
}

export interface CreateClientDto {
  /**  */
  name: string

  /**  */
  status: number

  /**  */
  type: ClientTypeEnum

  /**  */
  sirenNumber: string

  /**  */
  address: string

  /**  */
  email: string

  /**  */
  phoneNumber: string

  /**  */
  fax: string
}

export interface UpdateClientDto {
  /**  */
  name?: string

  /**  */
  status?: number

  /**  */
  type?: ClientTypeEnum

  /**  */
  sirenNumber?: string

  /**  */
  address?: string

  /**  */
  email?: string

  /**  */
  phoneNumber?: string

  /**  */
  id?: number

  /**  */
  fax?: string

  /**  */
  logo?: string

  /**  */
  sites?: Site[]

  /**  */
  admins?: Admin[]

  /**  */
  preferences?: ClientPreferences
}

export interface FinessDto {
  /**  */
  id: number

  /**  */
  finessNumber: string

  /**  */
  finessStructNumber: string

  /**  */
  siretNumber: string

  /**  */
  name: string

  /**  */
  phone: string

  /**  */
  fax: string

  /**  */
  street: string

  /**  */
  BP: string

  /**  */
  zipCode: string

  /**  */
  open_date: Date

  /**  */
  category: string

  /**  */
  status: string

  /**  */
  pricing: string

  /**  */
  PSPH: string
}

export interface AdminDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: EnumAdminDtoRole

  /**  */
  clientId: number

  /**  */
  client: Client
}

export interface CreateAdminDto {
  /**  */
  accountId: number

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  role: EnumCreateAdminDtoRole

  /**  */
  clientId: number
}

export interface UpdateAdminDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  title?: string

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string

  /**  */
  role?: EnumUpdateAdminDtoRole

  /**  */
  clientId?: number

  /**  */
  client?: Client
}

export interface SuperAdmin {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}

export interface CreateSuperAdminDto {
  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string

  /**  */
  password: string
}

export interface SuperAdminDto {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}

export interface UpdateSuperAdminDto {
  /**  */
  id?: number

  /**  */
  accountId?: number

  /**  */
  account?: Account

  /**  */
  title?: string

  /**  */
  firstName?: string

  /**  */
  lastName?: string

  /**  */
  motherMaidenName?: string

  /**  */
  phoneNumber?: string

  /**  */
  mobileNumber?: string

  /**  */
  sex?: SexEnum

  /**  */
  address?: string

  /**  */
  addressId?: number

  /**  */
  email?: string
}

export interface RppsDto {
  /**  */
  id: number

  /**  */
  rppsNumber: number

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  profession: string

  /**  */
  category: string

  /**  */
  skill: string

  /**  */
  skillType: string

  /**  */
  streetAddress: string

  /**  */
  zipCode: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  fax: string

  /**  */
  email: string

  /**  */
  emailPro: string
}

export interface ViewerDto {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  brand: string

  /**  */
  nuclear: boolean

  /**  */
  measurement: boolean

  /**  */
  dicom: boolean

  /**  */
  CE: boolean

  /**  */
  gsps: boolean

  /**  */
  sr: boolean

  /**  */
  _id?: string
}

export interface CreateViewerDto {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  brand: string

  /**  */
  nuclear: boolean

  /**  */
  measurement: boolean

  /**  */
  dicom: boolean

  /**  */
  CE: boolean

  /**  */
  gsps: boolean

  /**  */
  sr: boolean
}

export interface UpdateViewerDto {
  /**  */
  id?: number

  /**  */
  code?: string

  /**  */
  label?: string

  /**  */
  brand?: string

  /**  */
  nuclear?: boolean

  /**  */
  measurement?: boolean

  /**  */
  dicom?: boolean

  /**  */
  CE?: boolean

  /**  */
  gsps?: boolean

  /**  */
  sr?: boolean
}

export interface CreateWaitingRoomDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  receptionId: number

  /**  */
  examRoomIds: number[]

  /**  */
  called: Visit[]
}

export interface CreateExamRoomDto {
  /**  */
  shared: boolean

  /**  */
  xrayCompatible: boolean

  /**  */
  mutualized: boolean

  /**  */
  emergency: boolean

  /**  */
  color: string

  /**  */
  active: boolean

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  openingTime: Date

  /**  */
  recoveryTime: Date

  /**  */
  breakTime: Date

  /**  */
  closingTime: Date

  /**  */
  examIds: number[]
}

export interface UpdateExamRoomDto {
  /**  */
  shared?: boolean

  /**  */
  xrayCompatible?: boolean

  /**  */
  mutualized?: boolean

  /**  */
  emergency?: boolean

  /**  */
  color?: string

  /**  */
  active?: boolean

  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  openingTime?: Date

  /**  */
  recoveryTime?: Date

  /**  */
  breakTime?: Date

  /**  */
  closingTime?: Date

  /**  */
  prepRooms?: PrepRoom[]

  /**  */
  exams?: Exam[]

  /**  */
  examIds?: number[]
}

export interface CreatePrepRoomDto {
  /**  */
  status: EnumCreatePrepRoomDtoStatus

  /**  */
  isActive: boolean

  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string

  /**  */
  examRoomId: number
}

export interface UpdatePrepRoomDto {
  /**  */
  status?: EnumUpdatePrepRoomDtoStatus

  /**  */
  isActive?: boolean

  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string

  /**  */
  examRoom?: ExamRoom

  /**  */
  examRoomId?: number
}

export interface CreateReceptionDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface UpdateReceptionDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string
}

export interface CreateOfficeDto {
  /**  */
  siteId: number

  /**  */
  label: string

  /**  */
  code: string
}

export interface UpdateOfficeDto {
  /**  */
  id?: number

  /**  */
  site?: Site

  /**  */
  siteId?: number

  /**  */
  label?: string

  /**  */
  code?: string
}

export interface CreateExamDto {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: EnumCreateExamDtoModality

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: EnumCreateExamDtoBodyPart

  /**  */
  ageRange: EnumCreateExamDtoAgeRange

  /**  */
  instruction: EnumCreateExamDtoInstruction

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  siteId: number
}

export interface UpdateExamDto {
  /**  */
  code?: string

  /**  */
  label?: string

  /**  */
  modality?: EnumUpdateExamDtoModality

  /**  */
  procedureTime?: number

  /**  */
  sex?: SexEnum

  /**  */
  bodyPart?: EnumUpdateExamDtoBodyPart

  /**  */
  ageRange?: EnumUpdateExamDtoAgeRange

  /**  */
  instruction?: EnumUpdateExamDtoInstruction

  /**  */
  dose?: boolean

  /**  */
  contrast?: boolean

  /**  */
  shower?: boolean

  /**  */
  fasted?: boolean

  /**  */
  medication?: boolean

  /**  */
  id?: number

  /**  */
  type?: ExamType

  /**  */
  site?: Site

  /**  */
  siteId?: number
}

export interface CreateExamTypeDto {
  /**  */
  modality: EnumCreateExamTypeDtoModality

  /**  */
  sex: SexEnum

  /**  */
  ageRange: EnumCreateExamTypeDtoAgeRange

  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string
}

export interface UpdateExamTypeDto {
  /**  */
  modality?: EnumUpdateExamTypeDtoModality

  /**  */
  sex?: SexEnum

  /**  */
  ageRange?: EnumUpdateExamTypeDtoAgeRange

  /**  */
  id?: number

  /**  */
  code?: string

  /**  */
  label?: string
}

export interface User {
  /**  */
  id: number

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  title: string

  /**  */
  firstName: string

  /**  */
  lastName: string

  /**  */
  motherMaidenName: string

  /**  */
  phoneNumber: string

  /**  */
  mobileNumber: string

  /**  */
  sex: SexEnum

  /**  */
  address: string

  /**  */
  addressId: number

  /**  */
  email: string
}

export interface VisitDto {
  /**  */
  id: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  visitNumber: string

  /**  */
  studies: Study[]

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  admissionDate: Date

  /**  */
  dischargedDate: Date

  /**  */
  ticketNumber: string

  /**  */
  waitingRoomStatus: EnumVisitDtoWaitingRoomStatus

  /**  */
  waitingRoom?: WaitingRoom

  /**  */
  hospitalService: string

  /**  */
  admitSource: EnumVisitDtoAdmitSource

  /**  */
  emergency?: boolean

  /**  */
  dementia?: boolean

  /**  */
  visuallyImpaired?: boolean

  /**  */
  blind?: boolean

  /**  */
  crutches?: boolean

  /**  */
  stretcher?: boolean

  /**  */
  wheelchair?: boolean

  /**  */
  deaf?: boolean

  /**  */
  chatRequest?: boolean

  /**  */
  sawDoctor?: boolean
}

export interface ListQueryDto {
  /**  */
  filters?: Filter[]
  /**  */
  limit: number
  /**  */
  skip: number
}

export interface FindAvailabilityDto {
  /** */
  startDate: Date
  /** */
  endDate: Date
}

export interface AppointmentBase {
  /** */
  startDate: Date
  /** */
  endDate: Date
}

export interface AvailabilitySlot extends AppointmentBase {
  /** */
  title: string
  /** */
  doctorId: number
  /** */
  examRoomId: number
  /** */
  examTypeId?: number
  /** */
  bodyPart?: EnumExamBodyPart
  /** */
  isInjection: boolean
  /** */
  isDoubleInjection: boolean
  /** */
  isEmergency: boolean
  /** */
  fasted: boolean
  /** */
  medication: boolean
  /** */
  shower: boolean
}

export interface Prescription {
  /**  */
  status: PrescriptionStatusEnum

  /**  */
  id: number

  /**  */
  fileId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  medicalOrderId: number
}

export interface AvailabilitySlotItem extends AppointmentBase {
  /** */
  id: number

  /** */
  type: AvailabilitySlotItemTypeEnum

  appointmentSlotBlockReason: AppointmentSlotBlockReason

  availabilitySlot: AvailabilitySlot

  isEmergency: boolean

  types: ExamType[] | number[]

  appointment: Appointment | null
}
export interface MedicalOrder {
  /**  */
  status: MedicalOrderStatusEnum

  /**  */
  id: number

  /**  */
  visit: Visit

  /**  */
  visitId: number

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  patient: Patient

  /**  */
  patientId: number

  /**  */
  attendingDoctor: Doctor

  /**  */
  referringDoctor: Doctor

  /**  */
  consultingDoctor: Doctor

  /**  */
  prescription: Prescription

  /**  */
  consent: Consent

  /**  */
  reports: Report[]

  /**  */
  exam: Exam

  /**  */
  examRoom: ExamRoom

  /**  */
  examRoomId: number

  /**  */
  examId: number

  /**  */
  plannedDate: Date

  /**  */
  editedDate: Date

  /**  */
  cancelDate: Date

  /**  */
  description: string

  /**  */
  studyInstanceUID: string

  /**  */
  accessionNumber: string

  /**  */
  service: string

  /**  */
  shares: Shares[]

  /**  */
  radiologyStudy: RadiologyStudy
}

export interface Manufacturer {
  /**  */
  id: number

  /**  */
  name: string

  /**  */
  modelName: string
}

export interface Serie {
  /**  */
  uid: string

  /**  */
  radiologyStudy: RadiologyStudy

  /**  */
  number: number

  /**  */
  laterality: string

  /**  */
  createdDate: Date

  /**  */
  time: Date

  /**  */
  modality: string

  /**  */
  institutionName: string

  /**  */
  description: string

  /**  */
  performingPhysician: string

  /**  */
  manufacturer: Manufacturer

  /**  */
  instances: Instance[]
}

export interface Instance {
  /**  */
  uid: string

  /**  */
  serie: Serie

  /**  */
  number: number

  /**  */
  uiSopClassUid: string

  /**  */
  studyInstanceUid: string

  /**  */
  createdAt: Date

  /**  */
  contentDate: Date
}

export interface RadiologyStudy {
  /**  */
  uid: string

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  description: string

  /**  */
  imagesNumber: number

  /**  */
  series: Serie[]

  /**  */
  admittingDiagnoseDescription: string

  /**  */
  time: Date
}
export interface Shares {
  /**  */
  permission?: SharingPermissionEnum

  /**  */
  id: number

  /**  */
  startDate: Date

  /**  */
  endDate?: Date

  /**  */
  accountId: number

  /**  */
  account: Account

  /**  */
  medicalOrderId: number

  /**  */
  medicalOrder: MedicalOrder

  /**  */
  accessCode: string
}
export interface Appointment {
  id: number
  start: Date
  end: Date
  medicalOrder: MedicalOrder
  availabilitySlotItemId: number
  availabilitySlotItem: AvailabilitySlotItem
}

export interface AvailabilitySlotDto extends AppointmentBase {
  /** */
  id: number
  /** */
  title: string
  /** */
  doctor: Doctor
  /** */
  examRoom: ExamRoom
  /** */
  examRoomId: number
  /** */
  examType: ExamType
  /** */
  bodyPart: EnumExamBodyPart
  /** */
  isInjection: boolean
  /** */
  isDoubleInjection: boolean
  /** */
  isEmergency: boolean
  /** */
  fasted: boolean
  /** */
  medication: boolean
  /** */
  shower: boolean
  /** */
  slotType: SlotTypeEnum
  /** */
  recurrenceDay: RecurrenceDayEnum
  /** */
  recurrenceFrequency: RecurrenceFrequency
  /** */
  items: AvailabilitySlotItem[]
  /** */
  procedureTime: number
}

export interface UpdateAvailabilitySlotDto {
  /** */
  id?: number
  /** */
  title?: string
  /** */
  doctorId?: number
  /** */
  examRoomId?: number
  /** */
  bodyPart?: EnumExamBodyPart
  /** */
  isInjection?: boolean
  /** */
  isEmergency?: boolean
  /** */
  isFastRequired?: boolean
  /** */
  isMedicationRequired?: boolean
  /** */
  isShowerRequired?: boolean
  /** */
  recurrence?: number
  /** */
  procedureTime?: number
  /** */
  items?: AvailabilitySlotItem[]
}

export interface CreateAppointmentDto {
  /** */
  start: Date

  /** */
  end: Date

  /** */
  availabilitySlotItemId: number

  /** */
  patient: Partial<PatientDto>

  /** */
  examId: number

  /** */
  examRoomId: number

  /** */
  doctorId: number
}
export interface UpdateAppointmentDto extends Partial<CreateAppointmentDto> {}

export interface ExamDto {
  /**  */
  code: string

  /**  */
  label: string

  /**  */
  modality: ModalityEnum

  /**  */
  procedureTime: number

  /**  */
  sex: SexEnum

  /**  */
  bodyPart: BodyPartEnum

  /**  */
  ageRange: AgeRangeEnum

  /**  */
  instruction: InstructionEnum

  /**  */
  dose: boolean

  /**  */
  contrast: boolean

  /**  */
  shower: boolean

  /**  */
  fasted: boolean

  /**  */
  medication: boolean

  /**  */
  id: number

  /**  */
  type: ExamType

  /**  */
  site: Site

  /**  */
  siteId: number

  /**  */
  examRooms: ExamRoom[]
}

export interface ExamTypeDto {
  /**  */
  id: number

  /**  */
  code: string

  /**  */
  label: string

  /**  */
  site: Site

  /**  */
  siteId: number
}

export enum AccountRoleEnum {
  'Patient' = 'Patient',
  'Doctor' = 'Doctor',
  'Admin' = 'Admin',
  'Super Admin' = 'Super Admin',
}

export enum SexEnum {
  'A' = 'A',
  'F' = 'F',
  'M' = 'M',
  'N' = 'N',
  'O' = 'O',
  'U' = 'U',
}

export enum SharingPermissionEnum {
  'VIEW' = 'VIEW',
  'VIEW_AND_REPORT' = 'VIEW_AND_REPORT',
  'EXPORT' = 'EXPORT',
  'OWNER' = 'OWNER',
}

export enum ClientTypeEnum {
  'Public' = 'Public',
  'Private' = 'Private',
  'Mixed' = 'Mixed',
}
export enum EnumAdminRole {
  'Health Executive' = 'Health Executive',
  'Executive Director' = 'Executive Director',
  'Finance Director' = 'Finance Director',
  'Accountant' = 'Accountant',
  'Computer Technician' = 'Computer Technician',
  'Security' = 'Security',
  'RSSI' = 'RSSI',
  'DSI' = 'DSI',
  'Secretary' = 'Secretary',
  'Radiographer' = 'Radiographer',
  'Service Provider' = 'Service Provider',
  'IT Provider' = 'IT Provider',
  'Manager' = 'Manager',
  'Radiologist' = 'Radiologist',
  'Head of Emergencies' = 'Head of Emergencies',
  'Head of Radiology' = 'Head of Radiology',
  'Biomedical Engineer' = 'Biomedical Engineer',
  'Quality Manager' = 'Quality Manager',
  'Other' = 'Other',
}
export enum EnumSecurityPreferencesTwoFAMethod {
  'SMS' = 'SMS',
  'Email' = 'Email',
}
export enum EnumMedicalEquipmentType {
  'US' = 'US',
  'CT' = 'CT',
  'MR' = 'MR',
  'RCT' = 'RCT',
  'BDUS' = 'BDUS',
  'BMD' = 'BMD',
  'MG' = 'MG',
  'EOS' = 'EOS',
  'PT' = 'PT',
  'NM' = 'NM',
  'LINAC' = 'LINAC',
  'CP' = 'CP',
  'OT' = 'OT',
}
export enum EnumPrepRoomStatus {
  'Free' = 'Free',
  'Busy' = 'Busy',
}
export enum EnumExamTypeModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum EnumExamTypeAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumExamModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum EnumExamBodyPart {
  CEREBRAL = 'cerebral',
  SKULL = 'skull',
  PELVIS = 'pelvis',
  ANKLE = 'ankle',
  ELBOW = 'elbow',
  FACIAL = 'facial',
  SHOULDER = 'shoulder',
  KNEE = 'knee',
  HIP = 'hip',
  LEG = 'leg',
  HAND = 'hand',
  MAXILLARY = 'maxillary',
  PELVIMETRY = 'pelvimetry',
  FOOT = 'foot',
  WRIST = 'wrist',
  CERVICAL_SPINE = 'cervical-spine',
  THORACIC_SPINE = 'thoracic-spine',
  LUMBAR_SPINE = 'lumbar-spine',
  SINUS = 'sinus',
  THORACIC = 'thoracic',
  ABDOMINAL_PELVIC = 'abdominal-pelvic',
  THORACIC_ABDOMINAL_PELVIC = 'thoracic-abdominal-pelvic',
  OTHER = 'other',
}
export enum EnumExamAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumExamInstruction {
  'UNDERWEAR_FEMALEALE' = 'UNDERWEAR_FEMALEALE',
  'UNDERWEAR_FEMALE' = 'UNDERWEAR_FEMALE',
  'NO_TOP' = 'NO_TOP',
  'NO_PANTS' = 'NO_PANTS',
  'NO_FRAMED_BRA' = 'NO_FRAMED_BRA',
  'NO_BELT' = 'NO_BELT',
  'NO_SHOES_MALE' = 'NO_SHOES_MALE',
  'NO_SHOES_FEMALE' = 'NO_SHOES_FEMALE',
  'NO_JEWELLERY_HEAD' = 'NO_JEWELLERY_HEAD',
  'NO_JEWELLERY_BODY' = 'NO_JEWELLERY_BODY',
  'NO_JEWELLERY_HAND' = 'NO_JEWELLERY_HAND',
  'NO_PIERCING' = 'NO_PIERCING',
  'BLOUSE' = 'BLOUSE',
}
export enum EnumVisitWaitingRoomStatus {
  'Planned' = 'Planned',
  'Registered' = 'Registered',
  'In Waiting Room' = 'In Waiting Room',
  'In Exam' = 'In Exam',
  'With Doctor' = 'With Doctor',
  'Pending Result' = 'Pending Result',
  'Rresult Ok' = 'Rresult Ok',
  'Discharged' = 'Discharged',
  'Cancel' = 'Cancel',
}
export enum EnumVisitAdmitSource {
  'UNKMOWN' = 'UNKMOWN',
  'PHYSICIAN_REFERRAL' = 'PHYSICIAN_REFERRAL',
  'Clinic_REFERRAL' = 'Clinic_REFERRAL',
  'HMO_REFERRAL' = 'HMO_REFERRAL',
  'TRANSFER_FROM_HOSPITAL' = 'TRANSFER_FROM_HOSPITAL',
  'TRANSFER_FROM_SKILLED_NURSING_FACILITY' = 'TRANSFER_FROM_SKILLED_NURSING_FACILITY',
  'TRANSFER_FROM_ANOTHER_HEALTH_CARE_FACILITY' = 'TRANSFER_FROM_ANOTHER_HEALTH_CARE_FACILITY',
  'EMERGENCY_ROOM' = 'EMERGENCY_ROOM',
  'COURT_LAW_ENFORCEMENT' = 'COURT_LAW_ENFORCEMENT',
  'INFORMATION_NOT_AVAILABLE' = 'INFORMATION_NOT_AVAILABLE',
}
export enum EnumOrderStatus {
  'validated' = 'validated',
  'in-progress' = 'in-progress',
  'refused' = 'refused',
}
export enum EnumConsentStatus {
  'validated' = 'validated',
  'refused' = 'refused',
  'processing' = 'processing',
}
export enum EnumReportStatus {
  'in-progress' = 'in-progress',
  'final' = 'final',
  'non-final' = 'non-final',
}
export enum EnumStudyStatus {
  'A' = 'A',
  'CA' = 'CA',
  'CM' = 'CM',
  'DC' = 'DC',
  'ER' = 'ER',
  'HD' = 'HD',
  'IP' = 'IP',
  'RP' = 'RP',
  'SC' = 'SC',
}
export enum EnumStudyDtoStatus {
  'A' = 'A',
  'CA' = 'CA',
  'CM' = 'CM',
  'DC' = 'DC',
  'ER' = 'ER',
  'HD' = 'HD',
  'IP' = 'IP',
  'RP' = 'RP',
  'SC' = 'SC',
}

export enum ModalityEnum {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
  map = 'map',
}

export enum EnumLogDtoAction {
  'LOGIN' = 'LOGIN',
  'AUTO_LOGOUT' = 'AUTO_LOGOUT',
  'LOGOUT' = 'LOGOUT',
  'GET_CLIENT' = 'GET_CLIENT',
  'GET_SITE' = 'GET_SITE',
  'UPDATE_PHONE_NUMBER' = 'UPDATE_PHONE_NUMBER',
  'UPDATE_SOCIAL_SECURITY_NUMBER' = 'UPDATE_SOCIAL_SECURITY_NUMBER',
  'CHANGE_PASSWORD' = 'CHANGE_PASSWORD',
  'CHANGE_EMAIL' = 'CHANGE_EMAIL',
  'CHANGE_PROFILE' = 'CHANGE_PROFILE',
  'GET_PROFILE' = 'GET_PROFILE',
  'UPDATE_PROFILE' = 'UPDATE_PROFILE',
  'VIEW_STUDY' = 'VIEW_STUDY',
  'VIEW_REPORT' = 'VIEW_REPORT',
  'SHARE_STUDY' = 'SHARE_STUDY',
  'UNSHARE_STUDY' = 'UNSHARE_STUDY',
  'VIEW_IMAGES' = 'VIEW_IMAGES',
  'UPLOAD_ADIVCE' = 'UPLOAD_ADIVCE',
  'REMOVE_ADVICE' = 'REMOVE_ADVICE',
  'VIEW_ADVICE' = 'VIEW_ADVICE',
  'MERGE_PATIENT_MOVE_STUDY' = 'MERGE_PATIENT_MOVE_STUDY',
  'CHANGE_STATE_ADVICE' = 'CHANGE_STATE_ADVICE',
  'VIEW_PATIENT' = 'VIEW_PATIENT',
  'UPLOAD_CONSENT' = 'UPLOAD_CONSENT',
  'VIEW_CONSENT' = 'VIEW_CONSENT',
  'ADD_REFERRING' = 'ADD_REFERRING',
  'ADD_DOCTOR_TO_PORFILE' = 'ADD_DOCTOR_TO_PORFILE',
  'REMOVE_DOCTOR_FROM_PORFILE' = 'REMOVE_DOCTOR_FROM_PORFILE',
  'MERGE_PATIENT' = 'MERGE_PATIENT',
  'HL7_ORM_RECEIVED' = 'HL7_ORM_RECEIVED',
  'HL7_ORU_RECEIVED' = 'HL7_ORU_RECEIVED',
  'HL7_OMG_RECEIVED' = 'HL7_OMG_RECEIVED',
  'HL7_SIU_S12_RECEIVED' = 'HL7_SIU_S12_RECEIVED',
  'HL7_SIU_S14_RECEIVED' = 'HL7_SIU_S14_RECEIVED',
  'HL7_SIU_S15_RECEIVED' = 'HL7_SIU_S15_RECEIVED',
  'HL7_SIU_RECEIVED' = 'HL7_SIU_RECEIVED',
  'HL7_ADT_A40_RECEIVED' = 'HL7_ADT_A40_RECEIVED',
  'HL7_ADT_A04_RECEIVED' = 'HL7_ADT_A04_RECEIVED',
  'HL7_ADT_A08_RECEIVED' = 'HL7_ADT_A08_RECEIVED',
  'HL7_ADT_RECEIVED' = 'HL7_ADT_RECEIVED',
  'DCM_RECEIVED' = 'DCM_RECEIVED',
  'DCM_SENT' = 'DCM_SENT',
  'DCM_RECEIVED_LOCAL' = 'DCM_RECEIVED_LOCAL',
  'DCM_RECEIVED_CLOUD' = 'DCM_RECEIVED_CLOUD',
  'FINESS_AUTOCOMPLETE' = 'FINESS_AUTOCOMPLETE',
}
export enum EnumAdminDtoRole {
  'Health Executive' = 'Health Executive',
  'Executive Director' = 'Executive Director',
  'Finance Director' = 'Finance Director',
  'Accountant' = 'Accountant',
  'Computer Technician' = 'Computer Technician',
  'Security' = 'Security',
  'RSSI' = 'RSSI',
  'DSI' = 'DSI',
  'Secretary' = 'Secretary',
  'Radiographer' = 'Radiographer',
  'Service Provider' = 'Service Provider',
  'IT Provider' = 'IT Provider',
  'Manager' = 'Manager',
  'Radiologist' = 'Radiologist',
  'Head of Emergencies' = 'Head of Emergencies',
  'Head of Radiology' = 'Head of Radiology',
  'Biomedical Engineer' = 'Biomedical Engineer',
  'Quality Manager' = 'Quality Manager',
  'Other' = 'Other',
}
export enum EnumCreateAdminDtoRole {
  'Health Executive' = 'Health Executive',
  'Executive Director' = 'Executive Director',
  'Finance Director' = 'Finance Director',
  'Accountant' = 'Accountant',
  'Computer Technician' = 'Computer Technician',
  'Security' = 'Security',
  'RSSI' = 'RSSI',
  'DSI' = 'DSI',
  'Secretary' = 'Secretary',
  'Radiographer' = 'Radiographer',
  'Service Provider' = 'Service Provider',
  'IT Provider' = 'IT Provider',
  'Manager' = 'Manager',
  'Radiologist' = 'Radiologist',
  'Head of Emergencies' = 'Head of Emergencies',
  'Head of Radiology' = 'Head of Radiology',
  'Biomedical Engineer' = 'Biomedical Engineer',
  'Quality Manager' = 'Quality Manager',
  'Other' = 'Other',
}
export enum EnumUpdateAdminDtoRole {
  'Health Executive' = 'Health Executive',
  'Executive Director' = 'Executive Director',
  'Finance Director' = 'Finance Director',
  'Accountant' = 'Accountant',
  'Computer Technician' = 'Computer Technician',
  'Security' = 'Security',
  'RSSI' = 'RSSI',
  'DSI' = 'DSI',
  'Secretary' = 'Secretary',
  'Radiographer' = 'Radiographer',
  'Service Provider' = 'Service Provider',
  'IT Provider' = 'IT Provider',
  'Manager' = 'Manager',
  'Radiologist' = 'Radiologist',
  'Head of Emergencies' = 'Head of Emergencies',
  'Head of Radiology' = 'Head of Radiology',
  'Biomedical Engineer' = 'Biomedical Engineer',
  'Quality Manager' = 'Quality Manager',
  'Other' = 'Other',
}
export enum EnumCreatePrepRoomDtoStatus {
  'Free' = 'Free',
  'Busy' = 'Busy',
}
export enum EnumUpdatePrepRoomDtoStatus {
  'Free' = 'Free',
  'Busy' = 'Busy',
}
export enum EnumCreateExamDtoModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum BodyPartEnum {
  'cerebral' = 'cerebral',
  'skull' = 'skull',
  'pelvis' = 'pelvis',
  'ankle' = 'ankle',
  'elbow' = 'elbow',
  'facial' = 'facial',
  'shoulder' = 'shoulder',
  'knee' = 'knee',
  'hip' = 'hip',
  'leg' = 'leg',
  'hand' = 'hand',
  'maxillary' = 'maxillary',
  'pelvimetry' = 'pelvimetry',
  'foot' = 'foot',
  'wrist' = 'wrist',
  'cervical-spine' = 'cervical-spine',
  'thoracic-spine' = 'thoracic-spine',
  'lumbar-spine' = 'lumbar-spine',
  'sinus' = 'sinus',
  'thoracic' = 'thoracic',
  'abdominal-pelvic' = 'abdominal-pelvic',
  'thoracic-abdominal-pelvic' = 'thoracic-abdominal-pelvic',
  'other' = 'other',
}

export enum EnumCreateExamDtoBodyPart {
  'CEREBRAL' = 'CEREBRAL',
  'CRANE' = 'CRANE',
  'BASSIN' = 'BASSIN',
  'CHEVILLE' = 'CHEVILLE',
  'COUDE' = 'COUDE',
  'DE_LA_FACE' = 'DE_LA_FACE',
  'EPAULE' = 'EPAULE',
  'GENOU' = 'GENOU',
  'HANCHE' = 'HANCHE',
  'JAMBE' = 'JAMBE',
  'MAIN' = 'MAIN',
  'MAXILLAIRE' = 'MAXILLAIRE',
  'PELVIMETRIE' = 'PELVIMETRIE',
  'PIED' = 'PIED',
  'POIGNET' = 'POIGNET',
  'RACHIS_CERVICAL' = 'RACHIS_CERVICAL',
  'RACHIS_DORSAL' = 'RACHIS_DORSAL',
  'RACHIS_LOMBAIRE' = 'RACHIS_LOMBAIRE',
  'SINUS' = 'SINUS',
  'THORACIQUE' = 'THORACIQUE',
  'ABDOMINO_PELVIENNE' = 'ABDOMINO_PELVIENNE',
  'THORACO_ABDOMINO_PELVIEN' = 'THORACO_ABDOMINO_PELVIEN',
  'OTHER' = 'OTHER',
}
export enum EnumCreateExamDtoAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumCreateExamDtoInstruction {
  'UNDERWEAR_FEMALEALE' = 'UNDERWEAR_FEMALEALE',
  'UNDERWEAR_FEMALE' = 'UNDERWEAR_FEMALE',
  'NO_TOP' = 'NO_TOP',
  'NO_PANTS' = 'NO_PANTS',
  'NO_FRAMED_BRA' = 'NO_FRAMED_BRA',
  'NO_BELT' = 'NO_BELT',
  'NO_SHOES_MALE' = 'NO_SHOES_MALE',
  'NO_SHOES_FEMALE' = 'NO_SHOES_FEMALE',
  'NO_JEWELLERY_HEAD' = 'NO_JEWELLERY_HEAD',
  'NO_JEWELLERY_BODY' = 'NO_JEWELLERY_BODY',
  'NO_JEWELLERY_HAND' = 'NO_JEWELLERY_HAND',
  'NO_PIERCING' = 'NO_PIERCING',
  'BLOUSE' = 'BLOUSE',
}
export enum EnumUpdateExamDtoModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum EnumUpdateExamDtoBodyPart {
  'CEREBRAL' = 'CEREBRAL',
  'CRANE' = 'CRANE',
  'BASSIN' = 'BASSIN',
  'CHEVILLE' = 'CHEVILLE',
  'COUDE' = 'COUDE',
  'DE_LA_FACE' = 'DE_LA_FACE',
  'EPAULE' = 'EPAULE',
  'GENOU' = 'GENOU',
  'HANCHE' = 'HANCHE',
  'JAMBE' = 'JAMBE',
  'MAIN' = 'MAIN',
  'MAXILLAIRE' = 'MAXILLAIRE',
  'PELVIMETRIE' = 'PELVIMETRIE',
  'PIED' = 'PIED',
  'POIGNET' = 'POIGNET',
  'RACHIS_CERVICAL' = 'RACHIS_CERVICAL',
  'RACHIS_DORSAL' = 'RACHIS_DORSAL',
  'RACHIS_LOMBAIRE' = 'RACHIS_LOMBAIRE',
  'SINUS' = 'SINUS',
  'THORACIQUE' = 'THORACIQUE',
  'ABDOMINO_PELVIENNE' = 'ABDOMINO_PELVIENNE',
  'THORACO_ABDOMINO_PELVIEN' = 'THORACO_ABDOMINO_PELVIEN',
  'OTHER' = 'OTHER',
}
export enum InstructionEnum {
  'underwear-male' = 'underwear-male',
  'underwear-female' = 'underwear-female',
  'no-top' = 'no-top',
  'no-pants' = 'no-pants',
  'no-framed-bra' = 'no-framed-bra',
  'no-belt' = 'no-belt',
  'no-shoes-male' = 'no-shoes-male',
  'no-shoes-female' = 'no-shoes-female',
  'no-jewellery-head' = 'no-jewellery-head',
  'no-jewellery-body' = 'no-jewellery-body',
  'no-jewellery-hand' = 'no-jewellery-hand',
  'no-piercing' = 'no-piercing',
  'blouse' = 'blouse',
}
export enum EnumUpdateExamDtoAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumUpdateExamDtoInstruction {
  'UNDERWEAR_FEMALEALE' = 'UNDERWEAR_FEMALEALE',
  'UNDERWEAR_FEMALE' = 'UNDERWEAR_FEMALE',
  'NO_TOP' = 'NO_TOP',
  'NO_PANTS' = 'NO_PANTS',
  'NO_FRAMED_BRA' = 'NO_FRAMED_BRA',
  'NO_BELT' = 'NO_BELT',
  'NO_SHOES_MALE' = 'NO_SHOES_MALE',
  'NO_SHOES_FEMALE' = 'NO_SHOES_FEMALE',
  'NO_JEWELLERY_HEAD' = 'NO_JEWELLERY_HEAD',
  'NO_JEWELLERY_BODY' = 'NO_JEWELLERY_BODY',
  'NO_JEWELLERY_HAND' = 'NO_JEWELLERY_HAND',
  'NO_PIERCING' = 'NO_PIERCING',
  'BLOUSE' = 'BLOUSE',
}
export enum EnumCreateExamTypeDtoModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum EnumCreateExamTypeDtoAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumUpdateExamTypeDtoModality {
  'AR' = 'AR',
  'AS' = 'AS',
  'ASMT' = 'ASMT',
  'AU' = 'AU',
  'BDUS' = 'BDUS',
  'BI' = 'BI',
  'BMD' = 'BMD',
  'CD' = 'CD',
  'CF' = 'CF',
  'CP' = 'CP',
  'CR' = 'CR',
  'CS' = 'CS',
  'CT' = 'CT',
  'DD' = 'DD',
  'DF' = 'DF',
  'DG' = 'DG',
  'DM' = 'DM',
  'DOC' = 'DOC',
  'DS' = 'DS',
  'DX' = 'DX',
  'EC' = 'EC',
  'ECG' = 'ECG',
  'EPS' = 'EPS',
  'ES' = 'ES',
  'FA' = 'FA',
  'FID' = 'FID',
  'FS' = 'FS',
  'GM' = 'GM',
  'HC' = 'HC',
  'HD' = 'HD',
  'IO' = 'IO',
  'IOL' = 'IOL',
  'IVOCT' = 'IVOCT',
  'IVUS' = 'IVUS',
  'KER' = 'KER',
  'KO' = 'KO',
  'LEN' = 'LEN',
  'LP' = 'LP',
  'LS' = 'LS',
  'MA' = 'MA',
  'MG' = 'MG',
  'MR' = 'MR',
  'MS' = 'MS',
  'NM' = 'NM',
  'OAM' = 'OAM',
  'OCT' = 'OCT',
  'OP' = 'OP',
  'OPM' = 'OPM',
  'OPR' = 'OPR',
  'OPT' = 'OPT',
  'OPV' = 'OPV',
  'OSS' = 'OSS',
  'OT' = 'OT',
  'PLAN' = 'PLAN',
  'PR' = 'PR',
  'PT' = 'PT',
  'PX' = 'PX',
  'REG' = 'REG',
  'RESP' = 'RESP',
  'RF' = 'RF',
  'RG' = 'RG',
  'RTDOSE' = 'RTDOSE',
  'RTIMAGE' = 'RTIMAGE',
  'RTPLAN' = 'RTPLAN',
  'RTRECORD' = 'RTRECORD',
  'RTSTRUCT' = 'RTSTRUCT',
  'RWV' = 'RWV',
  'SEG' = 'SEG',
  'SM' = 'SM',
  'SMR' = 'SMR',
  'SR' = 'SR',
  'SRF' = 'SRF',
  'ST' = 'ST',
  'STAIN' = 'STAIN',
  'TG' = 'TG',
  'US' = 'US',
  'VA' = 'VA',
  'VF' = 'VF',
  'XA' = 'XA',
  'XC' = 'XC',
}
export enum EnumUpdateExamTypeDtoAgeRange {
  'Baby' = 'Baby',
  'Kid' = 'Kid',
  'Adult' = 'Adult',
  'Senior' = 'Senior',
  'KEY_' = '',
}
export enum EnumVisitDtoWaitingRoomStatus {
  'Planned' = 'Planned',
  'Registered' = 'Registered',
  'In Waiting Room' = 'In Waiting Room',
  'In Exam' = 'In Exam',
  'With Doctor' = 'With Doctor',
  'Pending Result' = 'Pending Result',
  'Rresult Ok' = 'Rresult Ok',
  'Discharged' = 'Discharged',
  'Cancel' = 'Cancel',
}
export enum EnumVisitDtoAdmitSource {
  'UNKMOWN' = 'UNKMOWN',
  'PHYSICIAN_REFERRAL' = 'PHYSICIAN_REFERRAL',
  'Clinic_REFERRAL' = 'Clinic_REFERRAL',
  'HMO_REFERRAL' = 'HMO_REFERRAL',
  'TRANSFER_FROM_HOSPITAL' = 'TRANSFER_FROM_HOSPITAL',
  'TRANSFER_FROM_SKILLED_NURSING_FACILITY' = 'TRANSFER_FROM_SKILLED_NURSING_FACILITY',
  'TRANSFER_FROM_ANOTHER_HEALTH_CARE_FACILITY' = 'TRANSFER_FROM_ANOTHER_HEALTH_CARE_FACILITY',
  'EMERGENCY_ROOM' = 'EMERGENCY_ROOM',
  'COURT_LAW_ENFORCEMENT' = 'COURT_LAW_ENFORCEMENT',
  'INFORMATION_NOT_AVAILABLE' = 'INFORMATION_NOT_AVAILABLE',
}

export enum SlotTypeEnum {
  CLOSING = 'CLOSING',
  OPENING = 'OPENING',
}

export enum RecurrenceDayEnum {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export enum RecurrenceFrequency {
  EveryDay,
  EveryWeek,
  EveryMonth,
  EveryYear,
}

export enum AvailabilitySlotItemTypeEnum {
  Closing = 'Closing',
  Opening = 'Opening',
}

export enum AppointmentSlotBlockReason {
  pause = 'pause',
  breakfast = 'breakfast',
  lunch = 'lunch',
}

export enum AgeRangeEnum {
  'all' = 'all',
  'baby' = 'baby',
  'kid' = 'kid',
  'adult' = 'adult',
  'senior' = 'senior',
}

export enum MedicalOrderStatusEnum {
  'available' = 'available',
  'canceled' = 'canceled',
  'completed' = 'completed',
  'discontinued' = 'discontinued',
  'error' = 'error',
  'hold' = 'hold',
  'unspecified' = 'unspecified',
  'replaced' = 'replaced',
  'scheduled' = 'scheduled',
}

export enum PrescriptionStatusEnum {
  'validated' = 'validated',
  'in-progress' = 'in-progress',
  'refused' = 'refused',
}
