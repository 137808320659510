import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { SharedStudyDto, StudyDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type studyState = {
  studies: {
    totalCount: number
    datas: StudyDto[]
  }
  shares: SharedStudyDto[]
  filters: Filter[]
  sorting: Sorting[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  selected: {
    study?: StudyDto
    order?: any
    report?: any
  }
  columnOrder: string[]
}

const initialState: studyState = {
  studies: {
    totalCount: 0,
    datas: [],
  },
  shares: [],
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  selection: [],
  selected: {},
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringDoctor',
    'open',
    'actions',
  ],
}

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    setStudies: (state, action: PayloadAction<PaginatedDto<StudyDto>>) => {
      state.studies = action.payload
    },
    removeStudies: (state) => {
      state.studies = initialState.studies
    },
    addStudy: (state, action: PayloadAction<StudyDto>) => {
      state.studies.datas = [...state.studies.datas, action.payload]
      state.studies.totalCount += 1
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelection: (state, action: PayloadAction<(string | number)[]>) => {
      const selection = action.payload
      const index = selection[0] as number
      state.selected.study = state.studies.datas[index]
    },
    setSelected: (state, action: PayloadAction<StudyDto>) => {
      state.selected.study = action.payload
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.selected.order = action.payload
    },
    removeOrder: (state) => {
      if (state.selected.order) {
        URL.revokeObjectURL(state.selected.order.data)
      }
      state.selected.order = initialState.selected.order
    },
    setReport: (state, action: PayloadAction<any>) => {
      state.selected.report = action.payload
    },
    removeReport: (state) => {
      if (state.selected.report) {
        URL.revokeObjectURL(state.selected.report.data)
      }
      state.selected.report = initialState.selected.report
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    setShares: (state, action: PayloadAction<SharedStudyDto[]>) => {
      state.shares = action.payload
    },
    removeShares: (state) => {
      state.shares = initialState.shares
    },
    addShare: (state, action: PayloadAction<SharedStudyDto>) => {
      state.shares = [...state.shares, action.payload]
    },
    removeShare: (state, action: PayloadAction<number>) => {
      state.shares = state.shares.filter(
        (share) => share.id !== action.payload,
      )
    },
    cleanStudy: () => initialState,
  },
})

export const {
  setStudies,
  removeStudies,
  addStudy,
  setFilters,
  setSorting,
  setPageSize,
  setCurrentPage,
  setSelection,
  setSelected,
  setColumnOrder,
  setOrder,
  removeOrder,
  setReport,
  removeReport,
  setShares,
  removeShares,
  addShare,
  removeShare,
  cleanStudy,
} = studySlice.actions

export default studySlice.reducer
