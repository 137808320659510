import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExamType } from '@services/api'

export interface ExamTypeState {
  examTypes: ExamType[]
}
const initialState: ExamTypeState = {
  examTypes: [],
}

const examTypeReducer = createSlice({
  name: 'examType',
  initialState,
  reducers: {
    setExamTypes: (state, action: PayloadAction<ExamType[]>) => {
      state.examTypes = action.payload
    },
  },
})
export const { setExamTypes } = examTypeReducer.actions
export default examTypeReducer.reducer
