import { Filter, Sorting } from '@devexpress/dx-react-grid'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { FinessDto, SiteDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'

type siteState = {
  sites: {
    totalCount: number
    datas: SiteDto[]
  }
  finess: {
    totalCount: number
    datas: FinessDto[]
  }
  filters: Filter[]
  sorting: Sorting[]
  currentPage: number
  pageSize: number
  selection: (string | number)[]
  selected?: SiteDto
  columnOrder: string[]
  options: any[]
}

const initialState: siteState = {
  sites: {
    totalCount: 0,
    datas: [],
  },
  finess: {
    totalCount: 0,
    datas: [],
  },
  options: [],
  filters: [],
  sorting: [{ columnName: 'plannedDate', direction: 'asc' }],
  currentPage: 0,
  pageSize: 10,
  selection: [],
  selected: undefined,
  columnOrder: [
    'plannedDate',
    'modality',
    'title',
    'referringSite',
    'open',
    'actions',
  ],
}

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSites: (state, action: PayloadAction<PaginatedDto<SiteDto>>) => {
      state.sites = action.payload
    },
    removeSites: (state) => {
      state.sites = initialState.sites
    },
    addSite: (state, action: PayloadAction<SiteDto>) => {
      state.sites.datas = [...state.sites.datas, action.payload]
      state.sites.totalCount += 1
    },
    updateSite: (state, action: PayloadAction<SiteDto>) => {
      state.sites.datas = state.sites.datas.map((site) =>
        site.id === action.payload.id ? action.payload : site,
      )
    },
    setFiness: (state, action: PayloadAction<PaginatedDto<FinessDto>>) => {
      state.finess = action.payload
    },
    removeFiness: (state) => {
      state.finess = initialState.finess
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setSorting: (state, action: PayloadAction<Sorting[]>) => {
      state.sorting = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    setSelection: (state, action: PayloadAction<(string | number)[]>) => {
      const selection = action.payload
      const index = selection[0] as number
      state.selected = state.sites.datas[index]
    },
    setSelected: (state, action: PayloadAction<SiteDto>) => {
      state.selected = action.payload
    },
    setColumnOrder: (state, action: PayloadAction<string[]>) => {
      state.columnOrder = action.payload
    },
    setOptions: (state, action: PayloadAction<any[]>) => {
      state.options = action.payload
    },
    cleanSite: () => initialState,
  },
})

export const {
  setSites,
  removeSites,
  addSite,
  updateSite,
  setFiness,
  removeFiness,
  setFilters,
  setSorting,
  setPageSize,
  setCurrentPage,
  setSelection,
  setSelected,
  setColumnOrder,
  setOptions,
  cleanSite,
} = siteSlice.actions

export default siteSlice.reducer
