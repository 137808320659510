import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'

import { RppsDto, RppsService } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { RppsActions } from '../actions'
import { setOptions, setRpps } from '../reducers/doctorReducer'

function* findAll({ payload }: AnyAction) {
  try {
    const rpps: PaginatedDto<RppsDto> = yield call(RppsService.findAll, payload)
    yield put(setRpps(rpps))
    yield put({ type: RppsActions.GET_RPPS_SUCCESS })
  } catch (e: any) {
    yield put({
      type: RppsActions.GET_RPPS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

// function* autocomplete({ payload }: AnyAction) {
//   try {
//     // yield call(delay, 500)
//     const datas: any[] = yield call(RppsService.autocomplete, payload)
//     console.log( 'datas and playload', payload , datas)
//     console.log(datas.length)

//     yield put(setOptions(datas))
//     yield put({ type: RppsActions.GET_AUTOCOMPLETE_RPPS_SUCCESS })
//   } catch (e: any) {
//     yield put({
//       type: RppsActions.GET_AUTOCOMPLETE_RPPS_ERROR,
//       payload: { message: e.response.data.message },
//     })
//   }
// }

function* autocompleteRpps({ payload }: AnyAction) {
  try {
    // yield call(delay, 500)
    const datas: any[] = yield call(RppsService.autocomplete, payload)

    yield put(setOptions(datas))
    yield put({ type: RppsActions.GET_AUTOCOMPLETE_RPPS_SUCCESS })
  } catch (e: any) {
    yield put({
      type: RppsActions.GET_AUTOCOMPLETE_RPPS_ERROR,
      payload: { message: e.response.data.message },
    })
  }
}

function* create(action: AnyAction) {
  try {
    yield call(RppsService.createAll, action.payload)
    // yield put({ type: SET_SNACK_SUCCESS, payload: {message: data.message}})
  } catch (error: any) {
    yield put({
      type: RppsActions.POST_RPPS_SUCESS,
      payload: { message: error.response.data.message, error },
    })
  }
}

export function* RppsFlow() {
  yield all([
    takeLatest(RppsActions.POST_RPPS_REQUEST, create),
    // throttle(500, RppsActions.GET_AUTOCOMPLETE_RPPS_REQUEST, autocomplete),
    takeLatest(RppsActions.GET_AUTOCOMPLETE_RPPS_REQUEST, autocompleteRpps), //edited
    takeLatest(RppsActions.GET_RPPS_REQUEST, findAll),
  ])
}

const rppsSagas = [fork(RppsFlow)]
export default rppsSagas
