import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AccountDto, CapchaDto } from '@services/api'
import { logout, getCaptcha } from '@state/thunks/authThunk'

// export type Account = PatientDto | DoctorDto | AdminDto | SuperAdminDto
type authState = {
  isAuth: boolean
  captcha: {
    data: string
    code: string
  }
  user?: AccountDto
  // profile?: User
  language: string
  signFail: {
    failure: number
    period: number
    window: number
    timeout: number
  }
}

const initialState: authState = {
  isAuth: false,
  captcha: {
    data: '',
    code: '',
  },
  language: 'en',
  signFail: {
    failure: 0,
    period: 0,
    window: 0,
    timeout: 0,
  },
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFulfilled: (state, { payload }: PayloadAction<any>) => {
      state.user = payload
      state.isAuth = true
      // state.profile = payload.profile
      state.captcha = initialState.captcha
    },
    // setProfile: (state, { payload }: PayloadAction<Account>) => {
    //   state.profile = payload
    // },
    setLanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload
    },
    setSignFail: (state, { payload }: PayloadAction<any>) => {
      state.signFail = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, () => {
        return initialState
      })
      .addCase(
        getCaptcha.fulfilled,
        (state, { payload }: PayloadAction<CapchaDto>) => {
          state.captcha = payload
        },
      )
  },
})

export const { loginFulfilled, setLanguage, setSignFail } = authSlice.actions

export default authSlice.reducer
